import { useEffect, useState } from "react"
import ModalHeader from "../../../../../Components/ModalHeader/ModalHeader";
import { ModalFooter, Scrollbar } from "../../../../../Constants/StyledComponents";
import { useUploadProcessProvider } from "../../../../../Providers/UploadProcessProvider";
import { UploadStepHeader } from "../../../../../Constants/StyledComponents";
import Accordion from "../../../../../Components/Accordion/Accordion";
import { UploadFilesModal } from "../../../../../Constants/StyledComponents";
import UploadAccordionFactory from "./UploadAccordionFactory";
import CNSFilterFunction from "./UploadAccordionContent/UploadMobergFilter"
import NatusFilterFunction from "./UploadAccordionContent/UploadNatusFilter";
import MedicationsFilterFunction from "./UploadAccordionContent/UploadMedicationFilter";
import { MobergButton } from "../../../../../Components/MobergButton/MobergButton";
import { MobergTheme } from "../../../../../Components/MobergThemes/MobergColors";
import { MobergButtonVariant } from "../../../../../Components/MobergButton/MobergButton";
import { MobergButtonShape } from "../../../../../Components/MobergButton/MobergButton";
import { useAuthProvider } from "../../../../../Providers/AuthProvider";
import { useEnvironmentVariablesProvider } from "../../../../../Providers/EnvironmentVariablesProvider";
import EDFFilterFunction from "./UploadAccordionContent/UploadEDFFilter";
import FileType from "./FileType";

const UploadDataPatient = ({
	setToPrevWindow,
	setToNextWindow,
}) => {
	const uploadProcessProvider = useUploadProcessProvider()
	const authProvider = useAuthProvider()
	const environmentVariablesProvider = useEnvironmentVariablesProvider()
	let uploadHeaderText = `Select Data for Patient ${uploadProcessProvider.patientID} at ${uploadProcessProvider.selectedSiteName}`

	let handleOverflowUploadHeaderText = uploadHeaderText.length > 75 ? `Select Data for Patient ${uploadProcessProvider.patientID.length > 15 ? uploadProcessProvider.patientID.slice(0, 15) + "..." : uploadProcessProvider.patientID} at ${uploadProcessProvider.selectedSiteName.length > 40 ? uploadProcessProvider.selectedSiteName.slice(0, 40) + "..." : uploadProcessProvider.selectedSiteName}` : uploadHeaderText

	const handleBack = () => {
		setToPrevWindow(true);
	}

	const handleNext = () => {
		setToNextWindow(true);
		uploadProcessProvider.setChangeUploadData(!uploadProcessProvider.changeUploadData)
	}

	/**
	* Accordion components wrap around the UploadAccordionFactory component let it be collapsed and expanded
	* The filter input prop is passed as unique ways to filter the data for each accordion
	*/
	const accordionData = {
		...(!environmentVariablesProvider.environmentVariables.CNS
			? {}
			: {
				'mobergData': {
					'label': "Moberg CNS data (patient.info)",
					'innerContent': <UploadAccordionFactory label={FileType.CNS} filterInput={CNSFilterFunction} fileType={FileType.CNS} />
				}
			}),
		...(!environmentVariablesProvider.environmentVariables.NATUS
			? {}
			: {
				'natusEEG': {
					'label': 'Natus EEG',
					'innerContent': <UploadAccordionFactory label={FileType.NATUS} filterInput={NatusFilterFunction} fileType={FileType.NATUS} />
				}
			}),
		...(!environmentVariablesProvider.environmentVariables.MEDICATIONS
			? {}
			: {
				'medications': {
					'label': 'Medications (.csv, .xlsx, .xls)',
					'innerContent': <UploadAccordionFactory label={FileType.MEDICATIONS} filterInput={MedicationsFilterFunction} fileType={FileType.MEDICATIONS} />
				}
			}),
		...(!environmentVariablesProvider.environmentVariables.EDF
			? {}
			: {
				'EDF': {
					'label': 'EDF (.edf)',
					'innerContent': <UploadAccordionFactory label={FileType.EDF} filterInput={EDFFilterFunction} fileType={FileType.EDF} />
				}
			})
	}

	const [enableNextButton, setEnableNextButton] = useState(false)

	/**
	 * Only when the table data is not empty, the next button is enabled
	 */
	useEffect(() => {
		if ((uploadProcessProvider.cnsTableData.length > 0) || (uploadProcessProvider.natusTableData.length > 0) || (uploadProcessProvider.medicationsTableData.length > 0) || (uploadProcessProvider.edfTableData.length > 0)) {
			setEnableNextButton(true)
		} else {
			setEnableNextButton(false)
		}
	}, [uploadProcessProvider.cnsTableData, uploadProcessProvider.natusTableData, uploadProcessProvider.medicationsTableData, uploadProcessProvider.edfTableData.length])

	useEffect(() => {
		if (authProvider.enableCheckActivity) {
			sessionStorage.removeItem('currentUploads')
		}
	}, [authProvider.enableCheckActivity])

	return (
		<div>
			<UploadFilesModal>
				<ModalHeader headerText={handleOverflowUploadHeaderText} resetCache={uploadProcessProvider.resetCache} />
				<Scrollbar style={{ paddingTop: "15px", paddingLeft: "47px", paddingRight: "47px", paddingBottom: "0px", height: "650px", overflowY: "auto", flex: 1, maxHeight: '650px' }}>
					<div style={{ marginBottom: '10px' }} >
						<UploadStepHeader>3. Review existing data on the cloud and upload new data</UploadStepHeader>
					</div>
					<div style={{ display: 'flex' }}>
						<h1 style={{ marginBottom: "10px", color: "#207DEA" }}>Data types</h1>
					</div>

					<Accordion data={accordionData} />

				</Scrollbar>
				<hr style={{ border: "1px solid #B3B3B3", width: "100%", margin: '0px auto' }} />
				<ModalFooter>
					<MobergButton
						theme={MobergTheme.BLUE}
						variant={MobergButtonVariant.OUTLINE}
						shape={MobergButtonShape.WIDE}
						onClick={handleBack}
					>
						Back
					</MobergButton>

					<MobergButton
						theme={MobergTheme.BLUE}
						variant={MobergButtonVariant.FILLED}
						shape={MobergButtonShape.WIDE}
						onClick={handleNext}
						disabled={!enableNextButton}
					>
						Next
					</MobergButton>
				</ModalFooter>
			</UploadFilesModal>
		</div>
	)
}

export default UploadDataPatient;