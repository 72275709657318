import { timeHour, timeMinute, timeSecond } from 'd3'

export function calculateTimedTicksAndFormat(start_time_ms, end_time_ms) {
    const second_in_ms = 1e3
    const minute_in_ms = second_in_ms * 60
    const hour_in_ms = minute_in_ms * 60
    const day_in_ms = hour_in_ms * 24

    const diff_in_ms = end_time_ms - start_time_ms

    if (diff_in_ms > day_in_ms) {
        return [timeHour.every(3), "%_I%p"]
    } else if (diff_in_ms > 3 * hour_in_ms) {
        return [timeHour, "%_I%p"]
    } else if (diff_in_ms > 45 * minute_in_ms) {
        return [timeMinute.every(15), "%_I:%M %p"]
    } else if (diff_in_ms > 15 * minute_in_ms) {
        return [timeMinute.every(5), "%_I:%M %p"]
    } else if (diff_in_ms > 3 * minute_in_ms) {
        return [timeMinute, "%_I:%M %p"]
    } else if (diff_in_ms > 45 * second_in_ms) {
        return [timeSecond.every(15), "%_I:%M:%S %p"]
    } else if (diff_in_ms > 15 * second_in_ms) {
        return [timeSecond.every(5), "%_I:%M:%S %p"]
    } else {
        return [timeSecond, "%_I:%M:%S %p"]
    }
}

export function isMidnight(date) {
    return date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0
}
