import { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { useLocation, useNavigate } from "react-router-dom";
import { LoginInput } from "../../../Components/Input/Input";
import styled from "styled-components";
import "@fontsource/montserrat";
import { FRONTEND_LINKS } from "../../../Constants/FrontendLinks";
import { useAuthProvider } from "../../../Providers/AuthProvider.js";
import loadingSvg from '../../../Constants/Graphics/smartneuro.svg'
import { postWithDependencyInjection } from "../../../Providers/EndpointProvider";
import * as MdIcons from 'react-icons/md';
import { BackButtonDiv, NormalText, InfoMessage } from "../../../Constants/StyledComponents";
import { useWorkspacesProvider } from "../../../Providers/WorkspacesProvider";
import { useOnMount } from "../../../Hooks/useOnMount.js";
import { useBackendLinksProvider } from "../../../Providers/BackendLinksProvider";

const CreateAccount = (props) => {
	const authProvider = useAuthProvider()
	const workspaceProvider = useWorkspacesProvider()
	const { LINKS } = useBackendLinksProvider()
	const [email, setEmail] = useState();

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [country, setCountry] = useState("");
	const [showPassword, setShowPassword] = useState(false)
	const [disableCreateAccountButton, setDisableCreateAccountButton] = useState(true)

	let navigate = useNavigate();

	const query = new URLSearchParams(useLocation().search);

	useOnMount(() => {
		authProvider.logout()
		props.setNavPage(false)

		let token = query.get('token')

		postWithDependencyInjection(token, workspaceProvider.selectedWorkspace, LINKS.ACCOUNT.CHECK_TOKEN).then((data) => {
			if (!data['permissions']['create_account']) {
				throw Error('User does not have permission to create an account. If you are currently logged in to another session, please logout and click the link again.')
			}
			setEmail(data['email'])

		}).catch((e) => {
			alert(e)
			navigate(FRONTEND_LINKS.MAIN)
		})

	})

	const createAccount = (firstName, lastName, confirmPassword, country) => {
		let body = {
			first_name: firstName,
			last_name: lastName,
			password: confirmPassword,
			country: country
		}

		let token = query.get('token')

		return postWithDependencyInjection(token, workspaceProvider.selectedWorkspace, LINKS.ACCOUNT.CREATE_ACCOUNT, body)
	}

	function handleCreateAccount(e) {
		const specialChars = /[`!@$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		if (e) {
			e.preventDefault()
		}

		if (password !== confirmPassword) {
			alert('Passwords do not match.')
			return
		}

		if (password.includes('#')) {
			alert('Passwords may not contain a hashtag.')
			return
		}

		if (!specialChars.test(password)) {
			alert('Password must contain at least one special character.')
			return
		}
		if (!(/[0-9]/.test(password))) {
			alert('Password must contain at least one number.')
			return
		}
		if (!(String(password).length >= 8)) {
			alert('Password must be at least 8 characters long')
			return
		}

		handleSendEmail()
		createAccount(firstName, lastName, confirmPassword, country).then((text) => {
			alert('Your account was created. Please check your email for confirmation.')
			authProvider.logout()
			navigate(FRONTEND_LINKS.MAIN)
		}).catch(e => {
			alert(e)
			resetPage()
		})

	}

	function showSendEmailInfoMessage() {
		let sendEmailEmailInfoMessage = document.getElementById("sendingEmailInfoMessage")
		if (!sendEmailEmailInfoMessage) return
		sendEmailEmailInfoMessage.style.display = 'block'
	}

	function hideSendEmailInfoMessage() {
		let sendEmailEmailInfoMessage = document.getElementById("sendingEmailInfoMessage")
		if (!sendEmailEmailInfoMessage) return
		sendEmailEmailInfoMessage.style.display = 'block'
	}

	function handleSendEmail() {
		setDisableCreateAccountButton(true)
		showSendEmailInfoMessage()
	}

	function handleBackToLogin() {
		navigate(FRONTEND_LINKS.MAIN);
	}

	function resetPage() {
		setDisableCreateAccountButton(false)
		hideSendEmailInfoMessage()
	}

	useEffect(() => {
		if (password !== confirmPassword || !password || !confirmPassword || !email || !firstName || !lastName || !country) {
			setDisableCreateAccountButton(true)
		} else {
			setDisableCreateAccountButton(false)
		}
	}, [password, confirmPassword, email, firstName, lastName, country])

	useEffect(() => {
		props.setNavPage(false)
	}, []);


	useEffect(() => {
		if (document.getElementsByClassName("css-b62m3t-container")) {
			for (let elem of document.getElementsByClassName("css-b62m3t-container")) {
				elem.style.color = "#000000"
			}
		}
	}, [])

	const [value, setValue] = useState('')
	const defaultCountryList = useMemo(() => countryList().getData(), [])

	defaultCountryList[236] = { value: 'AF', label: 'Afghanistan' }
	defaultCountryList[0] = { value: 'US', label: 'United States' }

	const changeHandler = value => {
		setValue(value)
		setCountry(value.label)
	}

	return !email ? (
		<div style={{ color: 'black', display: "block", textAlign: "center", marginTop: `${window.innerHeight / 4}px`, marginLeft: "-300px" }}>
			<img src={loadingSvg} alt="loading" style={{ width: "300px" }} />
			<h2 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "25px", marginTop: "10px" }}>Loading...</h2>
		</div>
	) : (
		<Container1>
			<BackButtonDiv onClick={() => { handleBackToLogin() }}>
				<MdIcons.MdOutlineArrowRightAlt size={32} style={{ cursor: "pointer", marginRight: "5px", marginTop: "-4px", transform: "rotate(180deg)" }} />
				<NormalText>Back</NormalText>
			</BackButtonDiv>
			<Wrapper>
				<Container2
					left={`${window.innerWidth / 2 - 160}px`}
				>
					<Form autoComplete="new-password">
						<h1>Create Account</h1>
						<div style={{ display: "inline-flex", height: "16px" }}>
							<h2>EMAIL</h2><p style={{ color: "#EA3D3D", fontSize: "30px", marginTop: "-6px", marginLeft: "4px" }}>*</p>
						</div>
						<LoginInput
							type="email"
							placeholder="Email"
							disabled={true}
							value={email || ""}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<div style={{ display: "inline-flex", height: "16px" }}>
							<h2>FIRST NAME</h2><p style={{ color: "#EA3D3D", fontSize: "30px", marginTop: "-6px", marginLeft: "4px" }}>*</p>
						</div>
						<LoginInput
							type="text"
							placeholder="First name"
							value={firstName || ""}
							onChange={(e) => setFirstName(e.target.value)}
						/>
						<div style={{ display: "inline-flex", height: "16px" }}>
							<h2>LAST NAME</h2><p style={{ color: "#EA3D3D", fontSize: "30px", marginTop: "-6px", marginLeft: "4px" }}>*</p>
						</div>
						<LoginInput
							type="text"
							placeholder="Last name"
							value={lastName || ""}
							onChange={(e) => setLastName(e.target.value)}
						/>
						<div style={{ display: "inline-flex", height: "16px" }}>
							<h2>PASSWORD</h2><p style={{ color: "#EA3D3D", fontSize: "30px", marginTop: "-6px", marginLeft: "4px" }}>*</p>
						</div>
						<LoginInput
							id="createPassword"
							type={showPassword ? "text" : (String(password).length === 0) ? "text" : "password"}
							placeholder="Password"
							value={password || ""}
							onChange={(e) => setPassword(e.target.value)}
							onCopy={(e) => { e.preventDefault() }}
							onPaste={(e) => { e.preventDefault() }}
						/>
						<div style={{ display: "inline-flex", height: "16px" }}>
							<h2>CONFIRM PASSWORD</h2><p style={{ color: "#EA3D3D", fontSize: "30px", marginTop: "-6px", marginLeft: "4px" }}>*</p>
						</div>
						<LoginInput
							id="createConfirmPassword"
							type={showPassword ? "text" : (String(confirmPassword).length === 0) ? "text" : "password"}
							style={{ display: "block" }}
							placeholder="Confirm password"
							value={confirmPassword || ""}
							onChange={(e) =>
								setConfirmPassword(e.target.value)
							}
							onCopy={(e) => { e.preventDefault() }}
							onPaste={(e) => { e.preventDefault() }}
						/>
						<div style={{ marginTop: "-10px", marginBottom: "5px" }}><input id="visibleConfirmPassword" type="checkbox"
							onChange={() => { setShowPassword(!showPassword) }}
						></input>
							<label for="visibleConfirmPassword" style={{ marginLeft: "4px" }}>Show password</label></div>
						<InfoMessage id="passwordRequirementsInfoMessage" style={{ height: "auto", display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "10px", marginTop: "8px" }}>
							<p><strong>Info!</strong> Password must be at least 8 characters, contain at least one number, and at least one special character.</p>
						</InfoMessage>

						<div style={{ display: "inline-flex", height: "16px", marginBottom: "5px" }}>
							<h2>COUNTRY</h2><p style={{ color: "#EA3D3D", fontSize: "30px", marginTop: "-6px", marginLeft: "4px" }}>*</p>
						</div>
						<Select options={defaultCountryList} value={value} onChange={changeHandler} />

						<button id='createAccountButton' style={{ marginTop: 35, pointerEvents: disableCreateAccountButton ? "none" : "all", opacity: disableCreateAccountButton ? "0.3" : "1", cursor: disableCreateAccountButton ? "default" : "pointer", background: "#ee6c4e", color: "#FFFFFF" }} onClick={(e) => { handleCreateAccount(e) }}>Create Account</button>
						<InfoMessage id="sendingEmailInfoMessage" style={{ width: "874px", display: "none", marginLeft: "-300px", marginRight: "auto", marginTop: "8px" }}><p><strong>Info!</strong> Email is sending...</p></InfoMessage>
					</Form>
				</Container2>
			</Wrapper>
		</Container1>
	);
};

const Container1 = styled.div`
	background: #eefcff;

	position: fixed;
	overflow-y: scroll;
	display: block;
	/* height: 100%; */
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`;

const Wrapper = styled.div`
	background-position: center;
	background-size: cover;
	background-repeat: repeat;
	width: 100%;
	height: auto;
	display: flex;
`;

const Container2 = styled.div`
	width: 100%;
    overflow-y: scroll;
	max-height: 90vh;
	position: fixed;
	width: 100%;
	height: 100%;
	padding-left: ${({ left }) => left};
	align-items: center;
	align-content: center;
	label {
		color: #293241;
	}
	input {
		color: #293241;
	}
`;

const Form = styled.form`
	display: flex;
	width: 100%;
	width: 304px;
	top: 284px;
	flex-direction: column;
	h1 {
		margin-bottom: 15px;
		margin-left: 3.5px;
		color: #293241;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 700;
		font-size: 32px;
		line-height: 39px;
	}
	h2 {
		font-family: "Montserrat";
		font-weight: 550;

		width: fit-content;
		font-size: 14px;
		color: #293241;
		padding: 0;
		margin: 0;

		margin-left: 6px;
	}
	button {
		width: 100%;
		max-width: 350px;
		min-width: 250px;
		height: 40px;
		border: none;
		margin: 1rem 0;
		margin-top: 0;
		box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
		border-radius: 8px;
		background-color: #ee6c4e;
		color: #fff;
		font-weight: 600;
		cursor: pointer;
		transition: all 0.2s ease-in;
		&:hover {
			transform: translateY(-3px);
		}
	}
	label {
		color: #293241;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 600;
	}
	input {
		color: #000000;
	}
`;

export default CreateAccount;

