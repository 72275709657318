import { Selection, EnterElement, axisTop, ScaleTime, D3DragEvent, drag, DragBehavior } from "d3"
import { D3DragOverlay } from "./D3DragOverlay"
import { ReactCallbacks } from "../../Types/ReactCallbacks"
import { D3OneToOneRenderable } from "./D3OneToOneRenderable"
import { getTimezoneOffset } from "date-fns-tz"

export type D3UTCAxisConfig = {
	viewScale: ScaleTime<any, any, any>
	fileScale: ScaleTime<any, any, any>
	liveModeEnabled: boolean
	onDragStart? (dragEvent: D3DragEvent<any, any, any>): void
	onDrag? (dragEvent: D3DragEvent<any, any, any>): void
	onDragEnd? (dragEnd: D3DragEvent<any, any, any>): void
}

export const MIN_WINDOW_TIME_MS = 1000
const timeZone = 'America/New_York'

export class D3UTCAxis extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3UTCAxisConfig> {
	private d3AxisClassName: string = "d3-axis-top"
	private dragOverlayHeight: number = 30
	private dragOverlayHorizontalExtension: number = 10
	private dragBehavior: DragBehavior<any, any, any>

	// Children
	private dragOverlay?: D3DragOverlay

	constructor(root: SVGGElement, config: D3UTCAxisConfig, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-utc-axis", reactCallbacks)

		this.dragBehavior = drag()
			.on("start", this.onDragStart)
			.on("drag", this.onDrag)
			.on("end", this.onDragEnd)

		this.render()
	}

	public enter = (newAxis: Selection<EnterElement, any, any, any>): Selection<SVGGElement, any, any, any> => {
		// Create the Axis Group
		const [startDate, endDate] = this.config.viewScale.domain()
		const startTime = startDate.getTime() + getTimezoneOffset(timeZone, startDate)
		const endTime = endDate.getTime() + getTimezoneOffset(timeZone, endDate)

		const axisGroup = newAxis
			.append("g")
			.attr("class", this.className)

		axisGroup.append("g")
			.attr("class", this.d3AxisClassName)
			.call(axisTop(this.config.viewScale.copy().domain([startTime, endTime])))
			.style("user-select", "none") // disables highlighting the ticks
		
		axisGroup.each(this.createChildren)

		return axisGroup 
	}

	public update = (updatedAxis: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const [startDate, endDate] = this.config.viewScale.domain()
		const startTime = startDate.getTime() + getTimezoneOffset(timeZone, startDate)
		const endTime = endDate.getTime() + getTimezoneOffset(timeZone, endDate)

		const d3AxisTop = updatedAxis.select("." + this.d3AxisClassName) as Selection<SVGGElement, any, any, any>
		d3AxisTop.call(axisTop(this.config.viewScale.copy().domain([startTime, endTime])))
		this.renderChildren()

		return updatedAxis
	}

	protected updateDerivedState(): void {
		this.updateChildren()
	}

	private onDragStart = (dragEvent: D3DragEvent<any, any, any>) => {
		if (this.config.liveModeEnabled) {
			return
		}

		if (this.config.onDragStart) {
			this.config.onDragStart(dragEvent)
		}
	}

	private onDrag = (dragEvent: D3DragEvent<any, any, any>) => {
		if (this.config.liveModeEnabled) {
			return
		}
		
		const startTime = this.config.viewScale.domain()[0].getTime()
		const endTime = this.config.viewScale.domain()[1].getTime()
		const recordingEndTime = this.config.fileScale.domain()[1].getTime()
		
		const width = this.config.viewScale.range()[1] - this.config.viewScale.range()[0]

		const range = endTime - startTime

		const sensitivity = range / width
		let { dx, dy } = dragEvent

		const pointerX = dragEvent.x - dx
		const zoomScaling = pointerX / width // keep zooming centered on cursor

		const startTimeZoomChange = 4 * dy * sensitivity * zoomScaling
		const endTimeZoomChange = -4 * dy * sensitivity * (1 - zoomScaling)

		let newStartTime = startTime + -dx * sensitivity + startTimeZoomChange
		let newEndTime = endTime + -dx * sensitivity + endTimeZoomChange

		if (newEndTime - newStartTime < MIN_WINDOW_TIME_MS && dy > 0) {
			newStartTime -= startTimeZoomChange
			newEndTime -= endTimeZoomChange
		} 
		
		if (newEndTime > recordingEndTime) {
			newEndTime = recordingEndTime
		} 
		
		this.config.viewScale.domain([newStartTime, newEndTime])

		if (this.config.onDrag) {
			this.config.onDrag(dragEvent)
		}

		requestAnimationFrame(() => {
			this.render()
		})
	}

	private onDragEnd = (dragEvent: D3DragEvent<any, any, any>) => {
		if (this.config.liveModeEnabled) {
			return
		}
		
		if (this.config.onDragEnd) {
			this.config.onDragEnd(dragEvent)

			// we need to force re-render the react components
			this.reactCallbacks.setRootConfig((previous: any) => ({...previous}))
		}
	}

	protected createChildren = (config: D3UTCAxisConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		const width = config.viewScale.range()[1]
		const boundingBox = {
			x: -this.dragOverlayHorizontalExtension,
			y: -this.dragOverlayHeight,
			width: width + 2 * this.dragOverlayHorizontalExtension,
			height: this.dragOverlayHeight,
		}

		if (this.config.onDrag || this.config.onDragStart) {
			this.dragOverlay = new D3DragOverlay(root, { boundingBox, dragBehavior: this.dragBehavior, cursor: this.config.liveModeEnabled ? "default" : "zoom-in" }, this.reactCallbacks)
		}
	}

	protected renderChildren = () => {
		this.dragOverlay?.render()
	}

	protected updateChildren = () => {
		const width = this.config.viewScale.range()[1]
		const boundingBox = {
			x: -this.dragOverlayHorizontalExtension,
			y: -this.dragOverlayHeight,
			width: width + 2 * this.dragOverlayHorizontalExtension,
			height: this.dragOverlayHeight,
		}
		this.dragOverlay?.updateConfig({ boundingBox, dragBehavior: this.dragBehavior, cursor: this.config.liveModeEnabled ? "default" : "zoom-in" })
	}
}
