
import * as d3 from 'd3'
import { DisplayTimesEvent } from '../../Display'
import { px } from "../../Utils/Convenience"
import { LEGEND_WIDTH, MIN_WINDOW_TIME, SETTINGS_WIDTH, DEFAULT_PAGE_SIZE } from "../Constants"
import ReactDOM from "react-dom"
import WindowSettings from '../../WindowSettings/WindowSettings'
import { calculateTimedTicksAndFormat, isMidnight } from '../../Ticks'
import VisualizationManager from '../../VisualizationManager'

export const WINDOW_TIME_PRESETS = [
    {label: 'Page Size',     time:           0},
    {label: '2 Sec',      time:        2000},
    {label: '4 Sec',      time:        4000},
    {label: '8 Sec',      time:        8000},
    {label: '15 Sec',     time:        15000},
    {label: '30 Sec',     time:        30000},
    {label: '1 Min',      time:        60000},
    {label: '15 Min',     time:       900000},
    {label: '30 Min',     time:      1800000},
    {label: '1 Hr',       time:      3600000},
    {label: '2 Hrs',      time:      7200000},
    {label: '4 Hrs',      time:     14400000},
    {label: '8 Hrs',      time:     28800000},
    {label: '16 Hrs',     time:     57600000},
    {label: '1 Day',      time:     86400000},
]

export const WAVEFORM_WINDOW_TIME_PRESETS = [
    {label: 'Page Size',     time:           0},
    {label: '2 Sec',      time:        2000},
    {label: '4 Sec',      time:        4000},
    {label: '8 Sec',      time:        8000},
    {label: '15 Sec',     time:        15000},
    {label: '30 Sec',     time:        30000},
    {label: '1 Min',      time:        60000},
    {label: '5 Min',      time:       300000},
    {label: '10 Min',     time:       600000}
 
]

export const SD_DETECTION_WINDOW_TIME_PRESETS = [
    { label: "Page Size",  time: 0 },
    { label: "1 Hr", time: 60 * 60 * 1000 },
    { label: "2 Hrs", time: 2 * 60 * 60 * 1000 },
    { label: "4 Hrs", time: 4 * 60 * 60 * 1000 },
    { label: "6 Hrs", time: 6 * 60 * 60 * 1000 }
]

export const PERSYST_TRENDS_WINDOW_TIME_PRESETS = [
    { label: "Page Size",  time: 0 },
    { label: "30 min", time: 30 * 60 * 1000 },
    { label: "1 Hr", time: 60 * 60 * 1000 },
    { label: "2 Hrs", time: 2 * 60 * 60 * 1000 },
    { label: "4 Hrs", time: 4 * 60 * 60 * 1000 },
    { label: "6 Hrs", time: 6 * 60 * 60 * 1000 }
]

export const CPPOPT_WINDOW_TIME_PRESETS = [
    { label: "8 hr", time: 8 * 60 * 60 * 1000 }
]

export const SCATTER_PLOT_SAMPLING_PRESETS = [
    {label: 'Page Size',     time:           0},
    {label: '2 Sec',      time:        2},
    {label: '4 Sec',      time:        4},
    {label: '8 Sec',      time:        8},
    {label: '15 Sec',     time:        15},
    {label: '30 Sec',     time:        30},
    {label: '1 Min',      time:        60},
    {label: '15 Min',     time:       900},
    {label: '30 Min',     time:      1800},
    {label: '1 Hr',       time:      3600},
]


export function createXAxis(selection) {
    const xaxis_container = selection
        .append('div')
        .attr('class', 'container-xaxis')
        .style('width', '100%')
        .style('display', 'flex')
    
    const xaxis_settings = xaxis_container.append('div')
        .attr('class', 'xaxis-settings')
        .style('display', 'flex')
        .style('justify-content', 'right')
        .style('align-items', 'end')
        .style('padding-right', '5px')
        .style('flex-shrink', 0)
        .style('flex-grow', 0)
        .style('flex-direction', 'column')
        .style('flex-basis', px(SETTINGS_WIDTH))

    const data = xaxis_container.append('div')
        .attr('class', 'xaxis-data')
        .style('flex', '0 1 100%')
    
    data
    .append("div")
    .each((display, index, nodes) => {
        ReactDOM.render(<WindowSettings display={display} />, nodes[index])
    })

    
    const legend = xaxis_container.append('div')
        .attr('class', 'xaxis-legend')
        .style('padding-left', '20px')
        .style('padding-right', '20px')
        .style('flex-shrink', 0)
        .style('flex-grow', 0)
        .style('flex-basis', px(LEGEND_WIDTH))
        .style('display', 'flex')
        .style('align-items', 'end') 

    data.append("div").each(function(d, i, nodes) {
        d.hasEEG = d.graphs.graphs.some(graph => graph.type === 'EEG')

        var page_size = d['DISPLAY'].window_size
        let page_size_value
        if(d.hasEEG){
            WAVEFORM_WINDOW_TIME_PRESETS.forEach(preset => {
                if (preset.label === page_size) {
                    page_size_value = preset.time
                }
            })
        } else {
        WINDOW_TIME_PRESETS.forEach(preset => {
                if (preset.label === page_size) {
                    page_size_value = preset.time
                }
            })
        }

        if (!page_size_value) {
            page_size_value = DEFAULT_PAGE_SIZE
        }

        const [ticks, tickFormat] = calculateTimedTicksAndFormat(d.windowScale.domain()[0], d.windowScale.domain()[1])

        const xAxis =  (!d.hasEEG)
            ? 
            d3.axisTop(d.windowScale)
                .ticks(ticks)
                .tickFormat(date => {
                    return isMidnight(date) ? d3.timeFormat('%m/%d/%Y')(date): d3.timeFormat(tickFormat)(date)
                })
            : 
            d3.axisTop(d.windowScale)
                .ticks(Math.min((d.end_time - d.start_time)/1000, 20))
                .tickFormat(d3.timeFormat("%X"))

        const g_xaxis = d3.select(this)
            .append('svg')
            .attr('id', `xaxis-d${d.id}`)
            .attr('class', 'xaxis')
            .attr('cursor', display => display.hasEEG ? 'default' : 'zoom-in')
            .style('width', '100%')
            .style('height', px(35))
            .attr('preserveAspectRatio', 'none')
            .append('g')
            .attr('class', 'g-xaxis')
            .attr('transform', 'translate(0, 29)')
            .call(xAxis)

        d.on('settimes.xaxis', function(e) {
            g_xaxis.call(xAxis.scale(d.windowScale))

            if (d.hasEEG) {
                g_xaxis.call(xAxis.ticks(Math.min((d.end_time - d.start_time)/1000, 16)))
            } else {
                const [ticks, tickFormat] = calculateTimedTicksAndFormat(d.windowScale.domain()[0], d.windowScale.domain()[1])
                g_xaxis.call(
                    d3.axisTop(d.windowScale)
                        .ticks(ticks)
                        .tickFormat(date => {
                            return isMidnight(date) ? d3.timeFormat('%m/%d/%Y')(date) : d3.timeFormat(tickFormat)(date)
                        })
                )
            }
        })
    })

    const xAxisDragHandler = d3
    .drag()
    .on("drag", (dragEvent) => {
        const display = dragEvent.subject

        if (display.hasEEG) {
            return
        }

        const range = display.end_time - display.start_time

        const sensitivity = range/display.inner_width
        let {dx, dy} = dragEvent

        if (dragEvent.sourceEvent.shiftKey) {
            if (Math.abs(dy / dx) >= 1){
                dx = 0;
            } else {
                dy = 0;
            } 
        }

        const pointerX = dragEvent.x - dx
        const zoomScaling = pointerX/display.inner_width // keep zooming centered on cursor

        const newStartTime = display.start_time + (-dx * sensitivity) + ((16 * dy * sensitivity) * zoomScaling)
        const newEndTime = display.end_time + (-dx * sensitivity) - ((16 * dy * sensitivity) * (1 - zoomScaling))

        if (newEndTime - newStartTime > MIN_WINDOW_TIME) {
            display.start_time = newStartTime
            display.end_time = newEndTime
        }

        const pageSizes = VisualizationManager.pageSize
        pageSizes[display.id] = newEndTime - new Date(newStartTime).getTime()
        VisualizationManager.setPageSize(structuredClone(pageSizes))

        display.windowScale.domain([display.start_time, display.end_time])
        display.call("settimes", new DisplayTimesEvent(display.start_time, display.end_time))       
        
    })
    .on("end", (dragEvent) => {
        const display = dragEvent.subject

        if (display.window_page_ratio > 1.0 || display.window_page_ratio < 1.0) {
            display.resetPages()
        
        }
    })

    d3.selectAll(".xaxis").call(xAxisDragHandler)
}