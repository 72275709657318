import { createContext, useContext, useState, useEffect } from "react";
import { useEndpointProvider } from "./EndpointProvider";
import { useAuthProvider } from "./AuthProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";
import { useBackendLinksProvider } from "./BackendLinksProvider";

const ModalitiesContext = createContext()

export const modalityUpdates = {
    MODALITIES: "modalities"
}

export const ModalitiesProvider = ({ children }) => {
    const [modalities, setModalities] = useState([]) // Holds the union of modalities for ALL patients.
    const { LINKS } = useBackendLinksProvider()

    console.log("PROVIDER RENDER: MODALITIES", { modalities })

    const authProvider = useAuthProvider()
    const endpointProvider = useEndpointProvider()

    async function update(...updates) {
        const initState = {
            [modalityUpdates.MODALITIES]: modalities
        }
        const dispatcher = new UpdateDispatcher(updates, initState, modalityUpdates)

        dispatcher.dispatch(modalityUpdates.MODALITIES,
            () => endpointProvider.post(LINKS.DATA.TRIALS.GET_MODALITIES_UNION,
                { patient_ids: [] }),
            modalities => setModalities(modalities))
    }

    // Make sure we don't persist any data between logins.
    useEffect(() => {
        if (!authProvider.userIsLoggedIn && modalities.length > 0) {
            setModalities([])
        }
    }, [authProvider, modalities.length])


    return (
        <ModalitiesContext.Provider value={{ modalities, update, setAllPatientModalities: setModalities }}>
            {children}
        </ModalitiesContext.Provider>
    )
}

export const useModalitiesProvider = () => {
    return useContext(ModalitiesContext)
}