import { Selection, EnterElement, ScaleTime, D3DragEvent, select } from "d3"
import { D3OneToOneRenderable } from "../D3OneToOneRenderable"
import { ReactCallbacks } from "../../../Types/ReactCallbacks"
import { TimeSeriesPageManager } from "../../../Data/TimeSeriesPageManager"
import { D3TimelineNavigator, D3TimelineNavigatorConfig } from "./D3TimelineNavigator"
import { createLeftCaratButton, createLeftLinedCaratButton, createRightCaratButton, createRightLinedCaratButton } from "./D3TimelineButtons"
import { D3TimelinePlayButton, D3TimelinePlayButtonConfig } from "./D3TimelinePlayButton"
import { D3TimelineSpeedDropdown, D3TimelineSpeedDropdownConfig } from "./D3TimelineSpeedDropdown"
import { Annotation } from "../../../../../../../Managers/VisualizationManager/Variables/Annotations"
import { Page } from "../../../Data/Page"


export type D3TimelineConfig = {
	id: string
	viewScale: ScaleTime<any, any, any>
	fileScale: ScaleTime<any, any, any>
	width: number
	annotations: Annotation[]
	playbackSpeed: number
	currentTimelineController: string | null
	isLinked: boolean
	liveModeEnabled: boolean
	timeZone: string
	goToStart(): void
	goToNextPage(): void
	goToPreviousPage(): void
	goToEnd(): void
	updateViewTimes(start: number, end: number): void
	onDragStart? (dragEvent: D3DragEvent<any, any, any>): void
	onDrag? (dragEvent: D3DragEvent<any, any, any>): void
	onDragEnd? (dragEvent: D3DragEvent<any, any, any>): void
}

export class D3Timeline extends D3OneToOneRenderable<SVGGElement, SVGGElement, D3TimelineConfig> {
	private height: number = 30
	private iconButtonWidth = 30
	private speedDropdownWidth = 50
	private elementSpacing = 8
	private pageManager: TimeSeriesPageManager<Page<any>>
	private firstPageButtonClassName: string = "d3-first-page-button"
	private previousPageButtonClassName: string = "d3-previous-page-button"
	private timelineNavigatorContainerClassName: string = "d3-timeline-navigator-container"
	private nextPageButtonClassName: string = "d3-next-page-button"
	private lastPageButtonClassName: string = "d3-last-page-button"
	private playButtonClassName: string = "d3-play-button"
	private speedDropdownClassName: string = "d3-speed-dropdown"

	// Children
	private timelineNavigator?: D3TimelineNavigator
	private playButton?: D3TimelinePlayButton
	private speedDropdown?: D3TimelineSpeedDropdown

	constructor(root: SVGGElement, config: D3TimelineConfig, pageManager: TimeSeriesPageManager<any, any>, reactCallbacks: ReactCallbacks<any>) {
		super(root, config, "d3-timeline", reactCallbacks)
		this.pageManager = pageManager
		this.config.fileScale.range([0, this.config.width - 5*this.iconButtonWidth - this.speedDropdownWidth - 6*(this.elementSpacing)])
		this.render()
	}


	protected updateDerivedState(): void {
		this.config.fileScale.range([0, this.config.width - 5*this.iconButtonWidth - this.speedDropdownWidth - 6*(this.elementSpacing)])
		this.updateChildren()
	}

	liveEndDateUpdated = () => { this.timelineNavigator?.render() }

	isPlaying = () => this.playButton?.isPlaying()

	viewTimesChanged() {
		this.timelineNavigator?.viewTimesChanged()
	}

	updateNotLoadedRegions = () => {
		this.timelineNavigator?.updateNotLoadedRegions()
	}

	playPause = () => {
		this.playButton?.playPause()
	}

	stop = () => {
		this.playButton?.stop()
	}

	private previousPageButtonOffset = () => this.iconButtonWidth + this.elementSpacing
	private timelineNavigatorOffset = () => 2 * (this.iconButtonWidth + this.elementSpacing)
	private nextPageButtonOffset = () => this.config.width - 3*this.iconButtonWidth - this.speedDropdownWidth - 3*this.elementSpacing
	private lastPageButtonOffset = () => this.config.width - 2*this.iconButtonWidth - this.speedDropdownWidth - 2*this.elementSpacing
	private playButtonOffset = () => this.config.width - this.iconButtonWidth - this.speedDropdownWidth - this.elementSpacing
	private speedDropdownOffset = () => this.config.width - this.speedDropdownWidth

	private goToEndOnHover = () => {
		if (this.playButton?.isPlaying()) {
			return
		}

		const tooltip = this.getTooltip()

		if (tooltip) {
			const fileEnd = this.config.fileScale.domain()[1]
			tooltip.moveTo(fileEnd)
			tooltip.cancelTooltipHide()
			tooltip.setText(tooltip.formatDate(fileEnd, { timeZone: true, timeZoneName: true }) as string)
		}
	}

	private goToStartOnHover = () => {
		if (this.playButton?.isPlaying()) {
			return
		}

		const tooltip = this.getTooltip()

		if (tooltip) {
			const fileStart = this.config.fileScale.domain()[0]
			tooltip.moveTo(fileStart)
			tooltip.cancelTooltipHide()
			tooltip.setText(tooltip.formatDate(fileStart, { timeZone: true, timeZoneName: true }) as string)
		}
	}

	private hideTooltip = () => this.getTooltip()?.hide(100)
	private getTooltip = () => this.timelineNavigator?.timelineTooltip

	protected enter(newElements: Selection<EnterElement, D3TimelineConfig, any, any>): Selection<SVGGElement, D3TimelineConfig, SVGGElement, any> {
		const container = newElements
			.append("g")
			.attr("class", this.className)

		const firstPageButton = container
			.append("g")
			.attr("class", this.firstPageButtonClassName)

		createLeftLinedCaratButton(
			firstPageButton, 
			this.config.goToStart,
			this.goToStartOnHover,
			this.hideTooltip
		)

		const previousPageButton = container.append("g")
			.attr("class", this.previousPageButtonClassName)
			.attr("transform", `translate(${this.previousPageButtonOffset()}, 0)`)

		createLeftCaratButton(previousPageButton, this.config.goToPreviousPage)

		container
			.append("g")
			.attr("class", this.timelineNavigatorContainerClassName)
			.attr("transform", `translate(${this.timelineNavigatorOffset()}, 0)`)
			.each(this.createTimelineNavigator)

		const nextPageButton = container
			.append("g")
			.attr("class", this.nextPageButtonClassName)
			.attr("transform", `translate(${this.nextPageButtonOffset()}, 0)`)

		createRightCaratButton(nextPageButton, this.config.goToNextPage)

		const lastPageButton = container
			.append("g")
			.attr("class", this.lastPageButtonClassName)
			.attr("transform", `translate(${this.lastPageButtonOffset()}, 0)`)

		createRightLinedCaratButton(
			lastPageButton,
			this.config.goToEnd,
			this.goToEndOnHover,
			this.hideTooltip
		)

		container
			.append("g")
			.attr("class", this.playButtonClassName)
			.attr("transform", `translate(${this.playButtonOffset()}, 0)`)
			.each(this.createPlayButton)

		// Playback Speed Dropdown
		container
			.append("g")
			.attr("class", this.speedDropdownClassName)
			.attr("transform", `translate(${this.speedDropdownOffset()}, 0)`)
			.each(this.createSpeedDropdown)

		this.enableDisableButtons()
		return container
	}

	update = (updatedElements: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		updatedElements
			.select("." + this.previousPageButtonClassName)
			.attr("transform", `translate(${this.previousPageButtonOffset()}, 0)`)

		updatedElements
			.select("." + this.timelineNavigatorContainerClassName)
			.attr("transform", `translate(${this.timelineNavigatorOffset()}, 0)`)

		updatedElements
			.select("." + this.nextPageButtonClassName)
			.attr("transform", `translate(${this.nextPageButtonOffset()}, 0)`)

		updatedElements
			.select("." + this.lastPageButtonClassName)
			.attr("transform", `translate(${this.lastPageButtonOffset()}, 0)`)

		updatedElements
			.select("." + this.playButtonClassName)
			.attr("transform", `translate(${this.playButtonOffset()}, 0)`)

		updatedElements
			.select("." + this.speedDropdownClassName)
			.attr("transform", `translate(${this.speedDropdownOffset()}, 0)`)

		this.renderChildren()

		return updatedElements
	}

	createTimelineNavigator = (config: D3TimelineConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.timelineNavigator = new D3TimelineNavigator(root, this.getTimelineNavigatorConfig(), this.pageManager, this.reactCallbacks)
	}

	createPlayButton = (config: D3TimelineConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.playButton = new D3TimelinePlayButton(root, this.getPlayButtonConfig(), this.reactCallbacks)
	}

	createSpeedDropdown = (config: D3TimelineConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.speedDropdown = new D3TimelineSpeedDropdown(root, this.getSpeedDropdownConfig(), this.reactCallbacks)
	}

	enableDisableButtons = () => {
		const buttonClasses = [this.firstPageButtonClassName, this.previousPageButtonClassName, this.nextPageButtonClassName, this.lastPageButtonClassName, this.playButtonClassName].map(name => "." + name)

		select(this.root)
			.selectAll(buttonClasses.join(", "))
			.attr("pointer-events", this.config.liveModeEnabled ? "none": "all")
			.select("g")
			.selectAll("path")
				.attr("stroke", this.config.liveModeEnabled ? "gray" : "#207dea")

		select(this.root)
			.select("." + this.speedDropdownClassName)
			.attr("pointer-events", this.config.liveModeEnabled ? "none": "all")
			.select("foreignObject").select("select").attr("disabled", this.config.liveModeEnabled ? true : null)
	}

	updateChildren = () => {
		this.timelineNavigator?.updateConfig(this.getTimelineNavigatorConfig())
		this.speedDropdown?.updateConfig(this.getSpeedDropdownConfig())
		this.playButton?.updateConfig(this.getPlayButtonConfig())
		this.enableDisableButtons()
	}

	renderChildren = () => {
		this.timelineNavigator?.render()
		this.speedDropdown?.render()
		this.playButton?.render()
	}

	private canControlPlaybackAndScrubbing = () => (
		!this.config.liveModeEnabled
		&& (this.config.currentTimelineController === null || this.config.currentTimelineController === this.config.id || !this.config.isLinked)
	)

	private getPlayButtonConfig = (): D3TimelinePlayButtonConfig => ({
		id: this.config.id, 
		color: "#207DEA", 
		viewScale: this.config.viewScale, 
		fileScale: this.config.fileScale,
		updateViewTimes: this.config.updateViewTimes,
		speed: this.config.playbackSpeed,
		canInteract: this.canControlPlaybackAndScrubbing() && !this.config.liveModeEnabled,
		timelineController: this.config.currentTimelineController,
		isLinked: this.config.isLinked
	})

	private getTimelineNavigatorConfig = (): D3TimelineNavigatorConfig => ({
		viewScale: this.config.viewScale,
		fileScale: this.config.fileScale,
		annotations: this.config.annotations,
		buttonsEnabled: !this.config.liveModeEnabled,
		scrubbingEnabled: this.canControlPlaybackAndScrubbing(),
		liveModeEnabled: this.config.liveModeEnabled,
		timeZone: this.config.timeZone,
		onDrag: this.config.onDrag,
		onDragEnd: this.config.onDragEnd,
	})

	private getSpeedDropdownConfig = (): D3TimelineSpeedDropdownConfig => ({
		speed: this.config.playbackSpeed,
		height: this.height,
		width: this.speedDropdownWidth
	})
}

