import { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import styled from 'styled-components';
import "@fontsource/source-sans-pro";
import * as MdIcons from 'react-icons/md';
import '../../../../index.css';
import { useLocation, useNavigate } from "react-router-dom";
import { useEndpointProvider } from '../../../../Providers/EndpointProvider';
import { FRONTEND_LINKS } from '../../../../Constants/FrontendLinks';
import { useModalProvider } from '../../../../Providers/ModalProvider';
import LogFileModal from './LogFileModal';
import { InfoMessage, WarningMessage, ErrorMessage, SuccessMessage } from '../../../../Constants/StyledComponents';
import MUITable from '../../../../Components/MUITable/MUITable';
import { MobergButton } from '../../../../Components/MobergButton/MobergButton';
import { MobergButtonVariant } from '../../../../Components/MobergButton/MobergButton';
import { MobergTheme } from '../../../../Components/MobergThemes/MobergColors';
import { localizeDate } from '../../../../Computation/utilFunctions';
import SubpageTabs from '../../../../Components/SubpageTabs/SubpageTabs';
import { SubHeader } from '../Subpages/Components/SubHeader';
import { useBackendLinksProvider } from '../../../../Providers/BackendLinksProvider';

const UploadHistorySubpage = () => {
    const endpointProvider = useEndpointProvider()
    const { createModal } = useModalProvider()
    const location = useLocation()
    const { LINKS } = useBackendLinksProvider()

    function useWindowSize() {
        const [size, setSize] = useState(window.innerHeight)

        useLayoutEffect(() => {
            const updateSize = () => setSize(window.innerHeight)
            const observer = new ResizeObserver(updateSize)

            observer.observe(document.body)

            return () => observer.disconnect()
        }, [])

        return size;
    }

    const size = useWindowSize()

    let navigate = useNavigate()

    const query = new URLSearchParams(useLocation().search);
    const UID = query.get("patient_id")
    const [uploadMessages, setUploadMessages] = useState({})

    const [patient, setPatient] = useState(location?.state)

    useEffect(() => {
        async function getPatient() {
            let body = {
                patient_primary_key: UID,
            }

            if (UID === undefined) {
                alert(`patient_id === undefined`)
                navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
            }

            try {
                const patientData = await endpointProvider.post(LINKS.DATA.PROFILING.GET_PATIENT, body)
                return patientData
            } catch (error) {
                alert(`Patient id=${UID} does not exist.`)
                navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)
            }
        }

        if (!patient || Object.keys(patient).length === 0) {
            getPatient().then(patientData => {
                setPatient(patientData)
            })
        }
    }, [UID])

    const getUploadMessages = useCallback(async (patient_id) => {
        let body = {
            patient_id: patient_id
        }

        let upload_messages = await endpointProvider.post(LINKS.DATA.UPLOAD.GET_UPLOAD_MESSAGES, body)

        upload_messages?.data.forEach(msg => {
            if (msg['status'] === 'PENDING') {
                msg.type = 'info'
            } else if (msg['status'] === 'CONVERTING') {
                msg.type = 'info'
            } else if (msg['status'] === 'FAILED') {
                msg.type = 'danger'
            } else if (msg['status'] === 'INITIATED') {
                msg.type = 'info'
            } else if (msg['status'] === 'CANCELED') {
                msg.type = 'canceled'
            }
            else {
                msg.type = 'success'
            }
        })

        return upload_messages
    }, [endpointProvider])


    useEffect(() => {
        let patient_id = UID
        getUploadMessages(patient_id)
    }, [])

    useEffect(() => {
        if (!patient) return
        getUploadMessages(UID).then((data) => {
            setUploadMessages(data?.data)
        }).catch((e) => {
            alert(e)
        })

    }, [patient])

    async function getLogFiles(row) {
        const upload_id = row['id']
        let body = {
            upload_id: upload_id,
        }
        renderModal(body)
    }

    function renderModal(body) {
        createModal(<LogFileModal
            escClose={false}
            clickOutsideClose={false}
            body={body}
        />)

    }

    function checkLog(status) {
        return status === 'FAILED' || status === 'SUCCESS'
    }

    function truncateString(str) {
        if (str.length > 70) {
            return (
                <span title={str}>
                    {str.slice(0, 70) + '...'}
                </span>
            )
        }
        return str
    }

    const columns = [
        { field: "timestamp", headerName: "Uploaded", valueGetter: params => localizeDate(Date.parse(params.row.timestamp)), minWidth: 200, visible: true, flex: 1 },
        { field: "user", headerName: "Uploaded By", minWidth: 160, visible: true, valueGetter: params => params?.row?.user?.email, flex: 1 },
        {
            field: "messages", headerName: "Most Recent Message", minWidth: 300, visible: true, renderCell: params => {
                const messageType = params.row.messages[params.row.messages.length - 1]?.message_type
                switch (messageType) {
                    case 'info':
                        return (
                            <InfoMessage>
                                <p><strong>Info! </strong>{truncateString(params.row.messages[params.row.messages.length - 1].message_text)}</p>
                            </InfoMessage>
                        )
                    case 'success':
                        return (
                            <SuccessMessage>
                                <p><strong>Success! </strong>{truncateString(params.row.messages[params.row.messages.length - 1].message_text)}</p>
                            </SuccessMessage>
                        )
                    case 'error':
                        return (
                            <ErrorMessage>
                                <p><strong>Error! </strong>{truncateString(params.row.messages[params.row.messages.length - 1].message_text)}</p>
                            </ErrorMessage>
                        )
                    case 'warning':
                        return (
                            <WarningMessage>
                                <p><strong>Warning! </strong>{truncateString(params.row.messages[params.row.messages.length - 1].message_text)}</p>
                            </WarningMessage>
                        )
                    case 'canceled':
                        return (
                            <CanceledMessage>
                                <p><strong>Canceled! </strong>{truncateString(params.row.messages[params.row.messages.length - 1].message_text)}</p>
                            </CanceledMessage>
                        )
                    default:
                        return null
                }
            },
            flex: 1
        },
        {
            field: "actions", flex: 0.4, headerName: "Actions", minWidth: 160, visible: true, headerAlign: 'center', renderCell: params => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
                        <MobergButton
                            theme={MobergTheme.BLUE}
                            variant={MobergButtonVariant.FILLED}
                            onClick={() => { getLogFiles(params.row) }}
                            disabled={!(checkLog(params.row.status))}>
                            View logs
                        </MobergButton>
                    </div>
                )
            }
        },
    ]

    const uploadTableToolbarProps = {
        createButton: { title: "Upload patient", isShow: false },
        deleteButton: { title: "Delete", isShow: false, },
        filterButton: { title: "Filter", isShow: false },
        exportButton: { isShow: false },
        searchInput: { isShow: true },
    }

    const uploadProps = {
        queryKey: "upload",
        endpoint: LINKS.DATA.UPLOAD.GET_UPLOAD_MESSAGES,
        body: { patient_id: UID },
    }

    useEffect(() => {
        if (uploadMessages.length === 0 || uploadMessages.length === undefined) {
            return
        }

        let completed = 0
        let failed = 0
        Object.entries(uploadMessages).sort((a, b) => b[1]['id'] - a[1]['id']).map(([key, val]) => {
            if (val['status'] === 'SUCCESS') {
                completed += 1
            } else if (val['status'] === 'FAILED') {
                failed += 1
            }
        })
        setNCompleted(completed)
        setNFailed(failed)

    }, [uploadMessages])

    const [nCompleted, setNCompleted] = useState(0)
    const [nFailed, setNFailed] = useState(0)

    return (
        <div>
            <SubpageTabs UID={UID} patient={patient} currentTab={'Uploads'} />

            <div style={{ padding: "0 32px", display: "flex", flexDirection: "row", width: "100%", position: "relative", justifyContent: 'space-between' }}>
                <SubHeader patient={patient}
                    startSlotChildren={[
                        <div style={{ display: 'flex', alignItems: 'center', gap: "8px", whiteSpace: "nowrap" }}>
                            <MdIcons.MdCheckCircleOutline size={'20px'} style={{ color: '#469970' }} />
                            <p style={{ fontFamily: 'Source Sans Pro', fontWeight: '400', fontSize: '16px', color: '#000000' }}>
                                {nCompleted} {nCompleted === 1 ? "Upload" : "Uploads"} Completed
                            </p>
                        </div>,

                        <div style={{ display: 'flex', alignItems: 'center', gap: "8px", whiteSpace: "nowrap" }}>
                            <MdIcons.MdWarningAmber size={'20px'} style={{ color: '#E54E57' }} />
                            <p style={{ fontFamily: 'Source Sans Pro', fontWeight: '400', fontSize: '16px', color: '#000000' }}>
                                {nFailed} {nFailed === 1 ? "Upload" : "Uploads"} Failed
                            </p>
                        </div>
                    ]}
                />
            </div>

            <MUITable columns={columns} tableToolbarProps={uploadTableToolbarProps} {...uploadProps} isCheckboxSelection={false} isRowSelectable={() => false} />

        </div>
    )
};

const CanceledMessage = styled.div`
	background: #FDE8B2;
	height: 30px;
	padding: 8px 8px;
	border-radius: 6px;
	p {
		color: #A66E00;
	}
`;

export default UploadHistorySubpage;