import React, { createContext, useState, useContext } from 'react';
import { useEnvironmentVariablesProvider } from './EnvironmentVariablesProvider';
import { DEFAULT_PATIENT_ID_LENGTH, DEFAULT_PATIENT_ID_TYPE } from '../Pages/Data/Visualize/UploadsSubpages/UploadWindows/PatientIDType';

// Create a context object
const UploadProcessContext = createContext({ submittedform: { formContent: {} } });

// Create a provider component
export const UploadProcessProvider = ({ children }) => {
    const environmentVariablesProvider = useEnvironmentVariablesProvider()

    const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false)

    const updateTermsAndConditionsChecked = () => {
        setTermsAndConditionsChecked(!termsAndConditionsChecked)
    }

    const [currentWindow, setCurrentWindow] = useState()

    const [selectedSiteID, setSelectedSiteID] = useState('')
    const [selectedSitePrimaryKey, setSelectedSitePrimaryKey] = useState('')
    const [selectedSiteName, setSelectedSiteName] = useState('')

    const [selectedProjectID, setSelectedProjectID] = useState('')
    const [selectedProjectPrimaryKey, setSelectedProjectPrimaryKey] = useState('')
    const [selectedProjectName, setSelectedProjectName] = useState('')

    const [patientID, setPatientID] = useState('')
    const [patientPrimaryKey, setPatientPrimaryKey] = useState('')

    const [existingPatientsCheckedProvider, setExistingPatientsCheckedProvider] = useState(true)
    const [newPatientCheckedProvider, setNewPatientCheckedProvider] = useState(false)

    const [removePHIAgreementChecked, setRemovePHIAgreementChecked] = useState(false)

    const [cnsFolders, setCNSFolders] = useState([]);
    const [medicationsFiles, setMedicationsFiles] = useState([]);
    const [natusFolders, setNatusFolders] = useState([]);
    const [edfFiles, setEDFFiles] = useState([]);

    const [cnsDirectoryNames, setCNSDirectoryNames] = useState([])
    const [natusDirectoryNames, setNatusDirectoryNames] = useState([])
    const [medicationsFileNames, setMedicationsFileNames] = useState([])
    const [edfFileNames, setEDFFileNames] = useState([])

    const [cnsTableData, setCNSTableData] = useState([]);
    const [medicationsTableData, setMedicationsTableData] = useState([]);
    const [natusTableData, setNatusTableData] = useState([]);
    const [edfTableData, setEDFTableData] = useState([])

    const [uploadingType, setUploadingType] = useState('');

    const [changeUploadData, setChangeUploadData] = useState(false);

    const [existingCNSDataTableData, setExistingCNSDataTableData] = useState()
    const [existingNatusDataTableData, setExistingNatusDataTableData] = useState()
    const [existingMedicationsDataTableData, setExistingMedicationsDataTableData] = useState()
    const [existingedfDataTableData, setExistingedfDataTableData] = useState()

    const [submittedForm, setSubmittedForm] = useState({})

    const [patientIDLength, setPatientIDLength] = useState(parseInt(environmentVariablesProvider.environmentVariables.PATIENT_ID_LENGTH) || DEFAULT_PATIENT_ID_LENGTH)
    const [patientIDType, setPatientIDType] = useState(environmentVariablesProvider.environmentVariables.PATIENT_ID_TYPE || DEFAULT_PATIENT_ID_TYPE)


    const resetCheckbox = () => {
        setTermsAndConditionsChecked(false);
        setExistingPatientsCheckedProvider(true);
        setNewPatientCheckedProvider(false);
        setRemovePHIAgreementChecked(false);
    }

    const checkFoldersIsEmpty = () => {
        if (cnsTableData.length === 0 && medicationsTableData.length === 0 && natusTableData.length === 0 && edfTableData.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    const resetUploadFolders = () => {
        setCNSFolders([]);
        setMedicationsFiles([]);
        setNatusFolders([]);
        setEDFFiles([]);
    }

    const resetDirectoryNames = () => {
        setCNSDirectoryNames([]);
        setNatusDirectoryNames([]);
        setMedicationsFileNames([]);
        setEDFFileNames([]);
    }

    const resetTableData = () => {
        setCNSTableData([]);
        setMedicationsTableData([]);
        setNatusTableData([]);
        setEDFTableData([]);
        setExistingCNSDataTableData();
        setExistingNatusDataTableData();
        setExistingMedicationsDataTableData();
        setExistingedfDataTableData();
    }

    const resetExistingTableData = () => {
        setExistingCNSDataTableData();
        setExistingNatusDataTableData();
        setExistingMedicationsDataTableData();
        setExistingedfDataTableData();
    }

    const resetString = () => {
        setSelectedSiteID('');
        setSelectedSiteName('');
        setPatientID('');
        setSelectedSitePrimaryKey({});
        setSelectedProjectID('');
        setSelectedProjectName('');
        setSelectedProjectPrimaryKey({});
        setPatientPrimaryKey({});
    }

    const resetForm = () => {
        setSubmittedForm({});
    }

    const resetCache = () => {
        resetCheckbox()
        resetUploadFolders()
        resetTableData()
        resetString();
        resetDirectoryNames();
        setCurrentWindow();
        resetForm();
    }

    function updateCurrentUploads(processingData) {
        const currentUploadsString = sessionStorage.getItem('currentUploads')
        const patient_id = processingData.patient_id
        const site_id = processingData.site_id
        const project_id = processingData.project_id

        const cns_directory_names = processingData.cns_directory_names
        const natus_directory_names = processingData.natus_directory_names
        const medications_file_names = processingData.medications_file_names
        const edf_file_names = processingData.edf_file_names

        if (currentUploadsString) {
            const currentUploads = JSON.parse(currentUploadsString)

            if (Object.keys(currentUploads).includes(`${site_id}-${project_id}-${patient_id}`)) {
                const currentDataObjects = currentUploads[`${site_id}-${project_id}-${patient_id}`]
                const uploadedObjs = [...cns_directory_names, ...natus_directory_names, ...medications_file_names, ...edf_file_names]

                const newCurrentDataObjects = currentDataObjects.filter(obj => !uploadedObjs.includes(obj))

                if (newCurrentDataObjects.length === 0) {
                    delete currentUploads[`${site_id}-${project_id}-${patient_id}`]
                } else {
                    currentUploads[`${site_id}-${project_id}-${patient_id}`] = newCurrentDataObjects
                }

                sessionStorage.setItem('currentUploads', JSON.stringify(currentUploads))
            }
        } else {
            alert('Upload does not exist')
            return
        }
    }

    return (
        <UploadProcessContext.Provider value={{
            cnsDirectoryNames, setCNSDirectoryNames, natusDirectoryNames, setNatusDirectoryNames, medicationsFileNames, setMedicationsFileNames, edfFileNames, setEDFFileNames, termsAndConditionsChecked, updateTermsAndConditionsChecked, selectedSiteID, setSelectedSiteID, selectedSiteName, setSelectedSiteName, patientID, setPatientID, existingPatientsCheckedProvider, setExistingPatientsCheckedProvider, newPatientCheckedProvider, setNewPatientCheckedProvider, removePHIAgreementChecked, setRemovePHIAgreementChecked, cnsTableData, setCNSTableData, medicationsTableData, setMedicationsTableData,
            natusTableData, setNatusTableData, edfTableData, setEDFTableData, setCNSFolders, setMedicationsFiles, setNatusFolders, setEDFFiles, uploadingType, setUploadingType, setChangeUploadData, changeUploadData, cnsFolders, medicationsFiles, natusFolders, edfFiles, resetCache, resetTableData, resetUploadFolders, checkFoldersIsEmpty, selectedSitePrimaryKey, setSelectedSitePrimaryKey, currentWindow, setCurrentWindow, existingCNSDataTableData, setExistingCNSDataTableData, existingNatusDataTableData, setExistingNatusDataTableData, existingMedicationsDataTableData, setExistingMedicationsDataTableData, existingedfDataTableData, setExistingedfDataTableData, resetDirectoryNames, resetExistingTableData, submittedForm, setSubmittedForm, selectedProjectID, setSelectedProjectID, selectedProjectName, setSelectedProjectName, selectedProjectPrimaryKey, setSelectedProjectPrimaryKey, patientPrimaryKey, setPatientPrimaryKey, updateCurrentUploads, patientIDLength, setPatientIDLength, patientIDType, setPatientIDType
        }}>
            {children}
        </UploadProcessContext.Provider>
    );
};

export const useUploadProcessProvider = () => {
    return useContext(UploadProcessContext)
}