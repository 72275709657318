import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuthProvider } from './AuthProvider';

// Create a context object
const UploadQueueContext = createContext();

// Create a provider component
export const UploadQueueProvider = ({ children }) => {
    const authProvider = useAuthProvider()

    const [patientID, setPatientID] = useState('')
    const [selectedSiteID, setSelectedSiteID] = useState('')
    const [selectedSiteName, setSelectedSiteName] = useState('')

    const [sitePrimaryKey, setSitePrimaryKey] = useState('')
    const [projectPrimaryKey, setProjectPrimaryKey] = useState('')
    const [patientPrimaryKey, setPatientPrimaryKey] = useState('')

    const [removeFromQueue, setRemoveFromQueue] = useState(false);

    const [itemToDelete, setItemToDelete] = useState()

    const [uploadID, setUploadID] = useState()

    const defaultVerticalTimelineData = [
        {
            title: "Preparing files",
            description: "Waiting to prepare files...",
            complete: false,
            progressStarted: false
        },
        {
            title: "Uploading",
            description: "",
            complete: false,
            progressStarted: false
        }
    ]

    const [verticalTimelineData, setVerticalTimelineData] = useState(
        defaultVerticalTimelineData
    )

    const [closeQueue, setCloseQueue] = useState(false)

    const [queuedSiteID, setQueuedSiteID] = useState('')
    const [queuedSiteName, setQueuedSiteName] = useState('')
    const [queuedPatientID, setQueuedPatientID] = useState('')
    const [queueLength, setQueueLength] = useState(0)
    const [queueStatus, setQueueStatus] = useState('')

    const [uploadFailed, setUploadFailed] = useState(false)

    const [cancelGUI, setCancelGUI] = useState(false)

    // True if there is an upload ongoing or there are uploads in queue
    const [uploadInProgress, setUploadInProgress] = useState(false)

    const updateVerticalTimeline = (index, title, description, complete, progressStarted) => {
        setVerticalTimelineData(prevData => [
            ...prevData.slice(0, index),
            {
                title: title,
                description: description,
                complete: complete,
                progressStarted: progressStarted
            },
            ...prevData.slice(index + 1)
        ])
    }

    function resetQueueProvider() {
        setPatientID('')
        setSelectedSiteID('')
        setSelectedSiteName('')
        setRemoveFromQueue(false)
        setItemToDelete()
        setUploadID()
        setVerticalTimelineData(defaultVerticalTimelineData)
        setQueuedSiteID('')
        setQueuedSiteName('')
        setQueuedPatientID('')
        setUploadInProgress(false)
    }

    useEffect(() => {
        if (!authProvider.userIsLoggedIn) {
            resetQueueProvider()
        }
    }, [authProvider])

    return (
        <UploadQueueContext.Provider value={{ patientID, setPatientID, selectedSiteID, setSelectedSiteID, selectedSiteName, setSelectedSiteName, verticalTimelineData, setVerticalTimelineData, closeQueue, setCloseQueue, removeFromQueue, setRemoveFromQueue, itemToDelete, setItemToDelete, queuedSiteID, setQueuedSiteID, queuedSiteName, setQueuedSiteName, queuedPatientID, setQueuedPatientID, queueLength, setQueueLength, queueStatus, setQueueStatus, uploadID, setUploadID, cancelGUI, setCancelGUI, updateVerticalTimeline, resetQueueProvider, uploadFailed, setUploadFailed, sitePrimaryKey, setSitePrimaryKey, projectPrimaryKey, setProjectPrimaryKey, patientPrimaryKey, setPatientPrimaryKey, uploadInProgress, setUploadInProgress }}>
            {children}
        </UploadQueueContext.Provider>
    )
}

export const useUploadQueueProvider = () => {
    return useContext(UploadQueueContext)
}