import { ScaleTime, scaleUtc } from "d3"
import { LayoutWindowId } from "../Types/LayoutWindowId"

type FileScaleEntry = {
	fileScale: ScaleTime<number, number, never>
	componentId: string
}

// Holds references to view scales for Data Review.
class FileScaleRegistry {
	private registry: Map<string, FileScaleEntry> = new Map()

	get = (key: LayoutWindowId, componentId: string, fileStartDate: Date, fileEndDate: Date): ScaleTime<number, number, never> =>  {
		const entry = this.registry.get(this.mapKey(key))

		// LayoutWindowId accounts for the specific window across different tabs.
		// We also check componentId because different components in the same physical location have different time scale restrictions.
		if (entry?.fileScale && entry?.componentId === componentId) {
			return entry.fileScale
		}

		const defaultEntry: FileScaleEntry = {
			fileScale: scaleUtc().domain([fileStartDate, fileEndDate]).clamp(true),
			componentId
		}

		this.registry.set(this.mapKey(key), defaultEntry)

		return defaultEntry.fileScale
	}

	set = (key: LayoutWindowId, componentId: string, fileScale: ScaleTime<number, number, never>) => {
		this.registry.set(this.mapKey(key), { fileScale, componentId })
	}

	// Allows all memory to be cleaned up by the garbage collector.
	clear = () => {
		this.registry.clear()
	}

	// Using Objects as Map keys can result in undefined behavior because it uses reference equality for comparison.
	// So, we turn the object into a string which can be checked for equality.
	private mapKey = (key: LayoutWindowId): string => `${key.layoutId}-${key.windowId}`
}

export const fileScaleRegistry = new FileScaleRegistry()
