const enum PatientIDType {
    ALPHA = "ALPHA", // case insensitive
    ALPHANUMERIC = "ALPHANUMERIC", // case insensitive
    NUMERIC = "NUMERIC",
    ALPHA_LOWER = "ALPHA_LOWER",
    ALPHA_UPPER = "ALPHA_UPPER",
    ALPHANUMERIC_UPPER = "ALPHANUMERIC_UPPER",
    ALPHANUMERIC_LOWER = "ALPHANUMERIC_LOWER",
}

export const DEFAULT_PATIENT_ID_TYPE = PatientIDType.NUMERIC

export const DEFAULT_PATIENT_ID_LENGTH = 4

export default PatientIDType;