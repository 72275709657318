import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthProvider } from "../../Providers/AuthProvider";
import { FRONTEND_LINKS } from "../../Constants/FrontendLinks";
import styled from "styled-components";
import { LoginInput } from "../../Components/Input/Input";
import loadingSvg from "../Whole/smartneuro.svg";
import { useModalProvider } from "../../Providers/ModalProvider";
import ErrorPopupModal from "../../Components/ErrorPopupModal/ErrorPopupModal";
import { useEnvironmentVariablesProvider } from "../../Providers/EnvironmentVariablesProvider"

const ErrorPage = (props) => {
    const authProvider = useAuthProvider()
    let navigate = useNavigate()
    const { createModal } = useModalProvider()
	const { environmentVariables } = useEnvironmentVariablesProvider()

    const [loading, setLoading] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

    useEffect(() => {
        props.setNavPage(false)
    }, [])

    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [showPassword, setShowPassword] = useState(false)

    const [, setLoginUpdate] = useState([])

    function updateLogin() {
        setLoginUpdate([])
    }

    useEffect(() => {
        props.setNavPage(false)

        window.addEventListener("resize", updateLogin)
        return () => {
            window.removeEventListener("resize", updateLogin)
        }
    })


    const handleForgotPassword = (e) => {
        if (e) {
            e.preventDefault()
        }
        navigate(FRONTEND_LINKS.SUBPAGES.FORGOT_PASSWORD.MAIN)
    }

    const handleLogin = useCallback((e) => {
		if (e?.preventDefault) {
			e.preventDefault()
		}

		let _email = document.getElementById('emailInput').value
		let _password = document.getElementById('passwordInput').value

		if (_email === '' || _email === undefined || !_email.includes('@')) {
			alert('Enter an email.')
			return
		}

		if (_password === '') {
			alert('Enter a password.')
			return
		}
		setLoading(true)

		authProvider.login(_email, _password).then((data) => {
			setLoading(false)
			props.setNavPage(true)

			navigate(FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN)

		}).catch((e) => {
			setIsErrorModalOpen(true)
			createModal(<ErrorPopupModal
				title={'There was a problem logging in'}
				errorMessage={`${e}`}
				onClose={() => { setLoading(false); setIsErrorModalOpen(false) }}
			/>)
		})
	})

    const enterLogin = useCallback((event) => {
        if (event.keyCode === 13) {
            handleLogin()
        }
    }, [handleLogin]);

    useEffect(() => {
		if (!isErrorModalOpen) {
			document.addEventListener("keydown", enterLogin)
			return () => {
				document.removeEventListener("keydown", enterLogin)
			}
		}
	}, [enterLogin, isErrorModalOpen])

    if (loading) {
        return (<>
            <div style={{ color: 'black', display: "block", textAlign: "center", marginTop: `${window.innerHeight / 4}px`, marginLeft: "-250px" }}>
                <img src={loadingSvg} alt="loading" style={{ width: "300px" }} />
                <h2 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "25px", marginTop: "10px" }}>Loading Workspace...</h2>
            </div>
        </>)
    } else {
        return (
            <div style={{ marginLeft: "-250px", textAlign: "center", paddingTop: `${window.innerHeight / 3}px`, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                {authProvider.userIsLoggedIn ?
                    <>
                        <div style={{ color: 'black', width: 'fit-content' }}>
                            <h1 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "700", fontSize: "45px" }}>
                                404
                            </h1>
                            <br />
                            <h2 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "600", fontSize: "30px", marginTop: "-25px" }}>
                                Not Found
                            </h2>
                        </div>
                        <Link to={FRONTEND_LINKS.MAIN} onClick={() => { props.setNavPage(true) }}>
                            <div style={{ color: 'black' }}>
                                <p style={{ font: "Montserrat", fontSize: "16px", color: "#207DEA" }}>
                                    Click here to go to main page.
                                </p>
                            </div>
                        </Link>
                    </>
                    :
                    <>
                        <div style={{ color: 'black', width: 'fit-content' }}>
                            <h2 style={{ fontFamily: "Montserrat", fontStyle: "normal", fontWeight: "600", fontSize: "30px", marginTop: "-25px" }}>
                                Not Logged In
                            </h2>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: '20px', width: 'fit-content' }}>
                            <Form>
                                <h1 style={{ marginLeft: "-245px" }}>EMAIL</h1>
                                <LoginInput id="emailInput" type="email" placeholder="Email" value={email || ""} onChange={e => setEmail(e.target.value)} />

                                <h1 style={{ marginLeft: "-205px" }}>PASSWORD</h1>
                                <LoginInput id="passwordInput" type={showPassword ? "text" : ((String(password).length === 0) ? "text" : "password")} placeholder="Password" value={password || ""} onChange={e => setPassword(e.target.value)} />

                                <button id="loginButton" type="button" onClick={(e) => { handleLogin(e) }}>LOG IN</button>

                                <HBox style={{ justifyContent: "end", paddingRight: "15px", height: "35px" }}>
                                    <input id="showPasswordCheckbox" type="checkbox" style={{ margin: "1px 6px 0 6px" }} onChange={() => { setShowPassword(!showPassword) }} />
                                    Show password
                                </HBox>

                                <HBox>
                                    <div><ExternalLink onClick={(e) => { handleForgotPassword(e) }}>Forgot Your Password?</ExternalLink></div>
                                </HBox>
                            </Form>
                        </div>
                    </>
                }
            </div>
        );
    }
}

const Form = styled.form`
    padding: 21px;
    border-radius: 5px;
	border: 1px solid #C0C0C0;
	background: #FFFFFF;
	display: flex;
	width: 344px;
	height: 359px;
	top: 331px;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
    margin: auto;
	h1 {
		font-family: "Montserrat";
		font-weight: 550;
		width: fit-content;
		font-size: 14px;
		color: #293241;
		padding: 0;
		margin: 0;
		text-align: left;
	}
	button {
		min-height: 40px;
		width: 304px;
		height: 40px;
		border: none;
		margin: -5px 0 15px;
		box-shadow: 0px 14px 9px -15px rgba(0, 0, 0, 0.25);
		border-radius: 6px;
		background-color: #ee6c4e;
		color: #fff;
		font-weight: 600;
		cursor: pointer;
		transition: all 0.2s ease-in;
		&:hover {
			transform: translateY(-3px);
		}
	}
`;

const HBox = styled.div`
		width: 325px;
		padding-bottom: 25px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
		height: 20px;
	font-family: "Source Sans Pro";
	font-size: 14px;
	color: #5F6775;
	div {
		width: fit-content;
		height: fit-content;
	}
	button {
		color: #0097EE;
		margin-top: -9px;
		margin-right: 13px;
		width: 100px;
		height: 40px;
		border-radius: 8px;
		border: 1px solid #C0C0C0;
		background: #FFFFFF;
		}
`;

const ExternalLink = styled.a`
	font-family: "Source Sans Pro";
	color: #0097EE;
	text-decoration: none;
`;

export default ErrorPage;
