import { DEFAULTS, VISUALIZATIONS } from "../../Constants/Formatting"
import * as d3 from 'd3'
import { add, idGenerator, remove, timestampToWindowX } from "./Utils/Convenience"

import { useEffect, useState } from "react"
import { Profiler } from "./Utils/Profiling"

export const default_rect_style = {
    "color": "#ff0000",
    "opacity": 0.2
}

function useConstant (value) {
	const [constant, setConstant] = useState(value)
	return constant
}

/** Time in seconds
 * @typedef {(number)} Seconds */
 
/** Time in milliseconds
 * @typedef {(number)} Millis */

/** Unix timestamp; a number representing time in milliseconds since 1970/1/1
 * @typedef {(Millis)} Timestamp */

/**
 * @name VisualizationManager
 * 
 * @category Visualization
 * @description Holds all pertinent global data needed for Visualization to run
 */
export default class VisualizationManager {
	

/* METADATA */
/* -------------------------------------------------------------------------*/

	/**
	 * The id of the current patient file
	 * @memberof VisualizationManager
	 * @type {PatientUID} */ 
	static patient_uid = undefined
	static patient_id = undefined
	/**
	 * @type {SiteID} */ 
	static selected_site_id = undefined
	/**
	 * @type {PatientID} */ 
	static selected_patient_id = undefined

	static patient_uids = undefined
	static selected_site_ids = {}
	static selected_patient_ids = {}

	static patient_fhir = {}
	static patient_fhir_guid = '17bee5e3d65-7a695e2d-cdb1-4613-bb79-113b35a4eab6'
	static patient_fhir_data = {}

	static patient_modality_data = {}

	static patient_files = {}
	static selected_files = {}


	/** @type {string} */
	static file_uid = null

	/** 
	 * The start datetime of the current upload file
	 * @type {Timestamp} */ 
	static file_start = null

	/**
	 * The end datetime of the current upload file 
	 * @type {Timestamp} */ 
	static file_end = null

	/**
	 * @returns {Millis} The total time represented in the upload file in milliseconds */ 
	static file_time = () => (VisualizationManager.file_end - VisualizationManager.file_start)

	static get ready () {
		return this.file_start !== null && this.file_end !== null && this.file_uid !== null && 
		this.file_start !== undefined && this.file_end !== undefined && this.file_uid !== undefined
	}

	static reset () {
		this.file_start = null
		this.file_end = null
		this.file_uid = null
		this.pages = []
		this.closeSocket()

		Object.keys(this.vizStates).forEach(key => {
			VisualizationManager[key] = this.vizStates[key]
		})
	}

	static start (file_uid, file_timestamps, modalities, patientModalities) {
		this.file_uid = file_uid
		this.file_timestamp = file_timestamps
		this.file_start = file_timestamps.start_time
		this.file_end = file_timestamps.end_time
		this.modalities = modalities
		this.patientModalities = patientModalities
		// VisualizationManager is DEPRECATED
	}

	static times = {}
	/* ---------------------------------------------------------------------*/


/* MODALITIES */
/* -------------------------------------------------------------------------*/

	static modalities_percentage = {}
	static modalities_time = {}

	static modalities_all = {}
	static modalities_percentage_all = {}
	static modalities_time_all = {}

	static modalities_overlapping = {}
	static modalities_percentage_overlapping = {}
	static modalities_time_overlapping = {}

	static modalities
	static eeg_modalities
	static numerics_modalities
	static waveform_modalities

	static socketListeners = {}
	static setSocketListener(listenerId, eventTrigger, callbackFunction) {
		VisualizationManager.socketListeners[listenerId] = {
			eventTrigger,
			callback: (socketData) => callbackFunction(socketData)
		}
	}
	static removeSocketListener(listenerId) {
		delete VisualizationManager.socketListeners[listenerId]
	}


	/* ---------------------------------------------------------------------*/
	


/* GRAPHS */
/* -------------------------------------------------------------------------*/


	/** Dictionary that stores the visualization area's current graphs
	 * @type {Object.<string, LineGraph>} */
	static graphs = {}

	/** Stores graph labels in display order (top to bottom) 
	 * @type {Array.<string>} */
	static graphsOrder = []

	static addGraph(label) {
		this.graphs[label]?.removeSVGs()
		// this.graphs[label] = new LineGraph(label)
		this.graphsOrder.push(label)
	}

	static addGraphs(labels) {
		labels.forEach(label => this.addGraph(label))
	}

	static removeAllGraphs() {
		// this.forAllGraphs(graph => {console.log(graph); graph.removeSVGs()})
		
		this.forAllGraphs(graph => {graph.removeSVGs()})
		this.graphs = {}
		this.graphsOrder = []
	}

	/** 
	 * All the current graphs as an array, ordered from top to bottom 
	 * @returns {Array.<LineGraph>} */
	static graphsArray = () => this.graphsOrder.map(label => this.graphs[label])

	/**
	 * @param {function(LineGraph)} func 
	 */
	static forAllGraphs = (func) => this.graphsArray().forEach(func)

	static forSelectedGraphs = (func) => this.selectedGraphs.forEach(func)

	/**
	 * The graph currently focused (hovered on by cursor)
	 * @returns {LineGraph} */
	static focusedGraph = () => this.graphs[this.focus_label]

	

	static selectedLabels = []
	static get selectedGraphs() {
		return this.selectedLabels.map(label => this.graphs[label])
	}

	static selectGraph(label) {
		if (this.selectedLabels.includes(label)) {
			this.selectedLabels = this.selectedLabels.filter(l => l !== label)
			return false
		} else {
			this.selectedLabels.push(label)
			return true
		}
	}

	static selectAllGraphs() {
		VisualizationManager.forAllGraphs(graph => {if (!VisualizationManager.selectedLabels.includes(graph.label_raw)) graph.select()})
	}

	static deselectAllGraphs() {
		VisualizationManager.forAllGraphs(graph => {if (VisualizationManager.selectedLabels.includes(graph.label_raw)) graph.select()})
	}
	
	/**
	 * ASDSAD
	 */
	 static forSelectedOrFocusedGraphs(func) {
		if (this.selectedGraphs.length > 0) {
			this.forSelectedGraphs(func)
        // TODO: check that at least one line graph is loaded
		} else if (this.focusedGraph) {
			func(this.focusedGraph())
		}
	}

	static decreaseMaxYValue = () => this.forSelectedOrFocusedGraphs(graph => graph.decreaseMaxYValue())
	static increaseMaxYValue = () => this.forSelectedOrFocusedGraphs(graph => graph.increaseMaxYValue())
	static decreaseMinYValue = () => this.forSelectedOrFocusedGraphs(graph => graph.decreaseMinYValue())
	static increaseMinYValue = () => this.forSelectedOrFocusedGraphs(graph => graph.increaseMinYValue())
	static translateYAxisUp = () => this.forSelectedOrFocusedGraphs(graph => graph.translateYAxisUp())
	static translateYAxisDown = () => this.forSelectedOrFocusedGraphs(graph => graph.translateYAxisDown())
	static decreaseGraphsHeight = () => this.forSelectedOrFocusedGraphs(graph => graph.decreaseGraphsHeight())
	static increaseGraphsHeight = () => this.forSelectedOrFocusedGraphs(graph => graph.increaseGraphsHeight())
	static moveGraphsUp = () =>  this.forSelectedOrFocusedGraphs(graph => graph.moveGraphsUp())
	static moveGraphsDown = () =>  this.forSelectedOrFocusedGraphs(graph => graph.moveGraphsDown())
	
	static hidden_graphs = {}
	static hidden_svgs = {}
	/* ---------------------------------------------------------------------*/



/* DOM Objects */
/* -------------------------------------------------------------------------*/
	static dataVisDiv 
	static yAxisVisDiv
	static legendVisDiv
	static timelinePreviewVisDiv 
	static timelineDiv

	static hideAllDivs () {
		this.dataVisDiv.style('display', 'none')
        this.yAxisVisDiv.style('display', 'none')
        this.legendVisDiv.style('display', 'none')
        this.timelinePreviewVisDiv.style('display', 'none')
		this.timelineDiv.style('display', 'none')
	}

	static showAllDivs() {
		this.dataVisDiv.style('display', 'block')
        this.yAxisVisDiv.style('display', 'block')
        this.legendVisDiv.style('display', 'block')
        this.timelinePreviewVisDiv.style('display', 'block')
		this.timelineDiv.style('display', 'flex')
	}
	/* ---------------------------------------------------------------------*/


	static selected_timestamp
	static selected_timestamp_xpos = () => timestampToWindowX(this.selected_timestamp)

	/* ---------------------------------------------------------------------*/

    static permissions = {}


/* INPUT MODES */ // JUST A PLAN NOT IMPLEMENTED YET RELALY
/* -------------------------------------------------------------------------*/
	/*
		Input modes:
			- click/normal (click points, drag timeline, point annotate)
			- selection (select graphs, select annotations)
			- brush (drag to zoom, drag to create annotation)
			- line (click twice to zoom, click twice to annotate)
			- enter (enter to type in time to jump to, enter to create annotation from dropdown)
		
		Escape will cancel all input modes and return to click/normal
	*/
	static inputMode = "click"
	/* ---------------------------------------------------------------------*/


	static cursor_mode = "start"
	static startBrush() {
		this.input_mode = "brush"

		d3.selectAll(".linegraph-svg")
            .on("mousedown.drag touchstart.drag touchmove.drag touchend.drag touchcancel.drag", null)
		d3.selectAll(".linegraph-svg > .overlay")
			.style("cursor", "crosshair")

        d3.select(document.defaultView)
            .on("mousemove.drag mouseup.drag", null)
		
	}

	static endBrush() {
		this.input_mode = "click"
		
		d3.selectAll(".linegraph-svg")
			.on("mousedown.brush touchstart.brush touchmove.brush touchend.brush touchcancel.brush", null)
			.call(d3.brush().move, null)

		d3.selectAll(".linegraph-svg > .overlay")
			.style("cursor", "default")

		d3.select(document.defaultView)
			.on("mousemove.brush mouseup.brush", null)
	}

	static startCursor() {
		this.input_mode = "cursor"

	}

	static startSelection () {

	}

	static endSelection () {

	}

	static startLine () {

	}

	static endLine () {

	}

	static xAxis = null


	static vizStates = {}

	/**
	 * Rerenders each graph
	 */
	static renderGraphs = () => this.forAllGraphs(graph => graph.render())



/* DATA */
/* -------------------------------------------------------------------------*/
	static socket
	static prefetchSocket
	static quality_report_socket

	static startSocket() {
		// VisualizationManager is DEPRECATED
	}
	
	static closeSocket() {
		this?.socket?.close()
		this.socket = undefined

		this?.prefetchSocket?.close()
		this.prefetchSocket = undefined

		this?.quality_report_socket?.close()
		this.quality_report_socket = undefined
	}
	

	static npages_cached = 30
	static max_loaded_pages = 100

	static pages = []

	/** @param {Timestamp} t */
	static pageAt(t) {
		return this.pages[Math.max(Math.floor((t-this.file_start)/this.graph_time(), 0))]
	}
	static get currentPageIndex () {
		return Math.max(Math.floor((this.graph_start()-this.file_start)/(this.graph_time())), 0)
	}
	static get currentPage () {
		return this.pages[this.currentPageIndex]
	}
	static get nextPage() {
		return this.pages[this.currentPageIndex+1]
	}

	static pageLoadOrder = Array(this.npages_cached + 1).fill(0).map((index, i) => Math.floor((i * (-1) ** i) / 2));
	static get neighborhood () {
		const indices = this.pageLoadOrder.map(index => index + this.currentPageIndex)
		return indices.map(index => this.pages[index]).filter(page => page !== undefined)
	}
	static loadNeighborhood = () => this.neighborhood.forEach(page => page.load())
	//static unloadOutsideNeighborhood = () => Page.loadedPages.forEach(page => this.neighborhood.includes(page) || page.unload())

	static resetPagesProfiler = new Profiler(5, 'resetPages') 
	static resetPagesCount = 0

	/* ---------------------------------------------------------------------*/


	static manual_max_y_values = {}
	static manual_min_y_values = {}

	static groups = {}

	static mode = "configure_mode"
	static hightlight_mode = "none"

	static horizontal_line = {
		color: "#FF3E23",
		annotate_mode_show: true,
		view_mode_show: false,
		always_show: false,
		hover: true
	}

	static hover_line = {
		color: "#FF3E23",
	}

	static data_path = {
		color: "black",
		color_out_of_range: "red",
		show_out_of_range: true,
	}
	

	static focus_group = ""

	static selected_groups = []
	//test above

	static wasMoved = false
    static wasZoomed = false

	static group = false

	static updateVisualizationArea() {}

	static visualizationArea = {}


	static addModalityToAnnotation = (annotation, modality) => {add(annotation.modalities, modality)}
	static removeModalityFromAnnotation = (annotation, modality) => {annotation.modalities = remove(annotation.modalities, modality)}

	static addModalityToSelectedAnnotation = (modality) => this.addModalityToAnnotation(this.selected_annotation, modality)
	static removeModalityFromSelectedAnnotation = (modality) => this.removeModalityFromAnnotation(this.selected_annotation, modality)

	// TODO: find a better way to control when the authoring tool is toggled on... state variable?
	static togglePipelineAuthoringTool() {
		document.getElementById("pipelineAuthoringTool").style.display = "block"
	}

	static annotation_key = 0

	static updateHotButtonById(new_hot_button) {
		VisualizationManager.hotkeys[VisualizationManager.hotkeys.findIndex(h => h.id === new_hot_button.id)] = new_hot_button
		VisualizationManager.updateHotButtonKeyList()
	}

	static deleteHotButtonById(hot_button) {
		VisualizationManager.hotkeys = VisualizationManager.hotkeys.filter(h => h.id !== hot_button.id)
		VisualizationManager.updateHotButtonKeyList()
	}

	static addNewHotButton() {
		VisualizationManager.hotkeys.push({ 
			"id": VisualizationManager.hotkeys[VisualizationManager.hotkeys.length-1].id+1,
			"key": "",
			"value": "",
			"text": "Annotation",
			"edit_key": true,
			"edit_text": true,
			"deletable": true
		})
		VisualizationManager.updateHotButtonKeyList()
	}

	static verifyHotButtonKey(k) {
		return VisualizationManager.hotkeys.filter(hot_button => hot_button.key === k).length === 0
	}

	static updateHotButtonKeyList() {
		VisualizationManager.hot_buttons_list = VisualizationManager.hotkeys.map((hot_button) => {return hot_button.key})
	}



	static testMedicationHoverCompare = false

	static renewMedicationArea = false

	static groups = []

	static group_graph = []


	static timeline = undefined

	static toggle_selected_timestamps() {
		Object.entries(VisualizationManager.graphs).forEach(([modality, graph]) => {
			graph.updateSelectedTimestampValue()
		}) 
	}

	static medication_grouping = []

	static selected_medications = ["Sodium_Chloride", "Fentanyl"]
	static page_number = 0
	static page_overlapping = 0

	static get width () {
		return (window.innerWidth - VISUALIZATIONS.TEXT_WIDTH - VISUALIZATIONS.GRAPH_PADDING.RIGHT) - 3 * DEFAULTS.PADDING
	}

	
}

const dispatchName = key => key + 'Dispatch'
const setterName = key => 'set' + key[0].toUpperCase() + key.slice(1)
const hooksName = key => key + 'Hooks'

/**
 * @name CreateVizState
 * @category Visualization
 * 
 * @description Creates a global state variable that can be accessed and updated both within any React component and imperatively from {@link VisualizationManager} 
 * 
 * Returns a React Hook that functions the same way as the useState Hook does.
 * 
 * When used, a corresponding state and setState function will be bound to VisualizationManager. 
 * For example, if `key` is 'foo', then after calling {@link CreateVizState}, both {@link VisualizationManager}.foo and {@link VisualizationManager}.setFoo will be accessible,
 * and will cause React State updates like actual React Hooks do.
 * 
 * @param {string} key 			The key to use when binding this variable to {@link VisualizationManager}.
 * @param {any} initialValue 	The starting value of this state
 * 
 * @returns {useState} A useState-like Hook that can be destructured into [state, setState]
 * 
 * @example
 * const useCounter = createVizState ('counter')
 * 
 * // All of the following will share the same counter state
 * 
 * function Component (props) {
 *     const [counter, setCounter] = useCounter()
 * 	   return <button onClick={()=>setCounter(counter+1)}>{counter}</button>
 * }
 * 
 * function OtherComponent (props) {
 *     const [counter, setCounter] = useCounter()
 * 	   return <button onClick={()=>setCounter(counter-1)}>{counter}</button>
 * }
 * 
 * function All (props) {
 *     const [counter, setCounter] = useCounter()
 * 	   return <div>
 *         <h1>Current count: {counter}</h1>
 *         <Component />
 *         <OtherComponent />
 *     </div>
 * }
 * 
 * // ... e.g, d3 code or socket code
 * 
 * function SomeFunctionNotCalledInAReactContext () {
 *     doSomeCalculation(VisualizationManager.counter)
 * 
 *     if (someCondition)
 *         VisualizationManager.setCounter(500)
 *     else
 *         VisualizationManager.setCounter(0)
 * }
 * 
 * @function
 * @see CreateVizReducer
 * @see VizEffect
 */
export function CreateVizState (key, initialValue) {
	const variable = key
	const setVariable = setterName(key)
	const variableHooks = hooksName(key)

	const hookIDGenerator = new idGenerator()

	const setters = {}
	const setAllStates = (value) => {
		const newValue = value instanceof Function ? value(VisualizationManager[key]) : value
		
		VisualizationManager[variable] = newValue
		Object.values(setters).forEach(setter => setter(newValue))
		VisualizationManager[variableHooks].forEach(hook => hook(newValue))

		console.log(`[📖] ${key} updated: `, newValue)
	}

	VisualizationManager[key] = initialValue
	VisualizationManager[setVariable] = setAllStates
	VisualizationManager[variableHooks] = []
	VisualizationManager.vizStates[key] = initialValue

	function useVisualizationManagerVariable() {
		const [state, setState] = useState(VisualizationManager[key])
		const hookID = useConstant(hookIDGenerator.newID())

		useEffect(() => {
			setters[hookID] = setState
			return () => delete setters[hookID]
		}, [setState, hookID])

		return [state, setAllStates]
	}

	return useVisualizationManagerVariable
}

/**
 * @name CreateVizReducer
 * @category Visualization
 * 
 * @description Counterpart to createVizState-- createVizReducer creates a useReducer 
 * 
 * Returns a React Hook that functions the same way as the useReducer Hook does.
 * 
 * When used, a corresponding state and dispatch function will be bound to VisualizationManager. 
 * For example, if `key` is 'foo', then after calling createVizState, both VisualizationManager.foo and VisualizationManager.fooDispatch will be accessible,
 * and will cause React State updates like actual React Hooks do.
 * 
 * @param {string} key 				The key to use when binding this variable to VisualizationManager.
 * @param {function(any)} reducer	The reducer function
 * @param {any} initialValue 		The starting value of this state
 * 
 * @returns {useReducer} A useReducer-like Hook that can be destructured into [state, dispatch]
 * 
 * @function
 * @see CreateVizState
 * @see VizEffect
 * @todo
 * - Add example
 * - Make indistinguishable from useReducer
 */
export function CreateVizReducer (key, reducer, initialValue) {
	const variable = key
	const variableDispatch = dispatchName(key)
	const variableHooks = hooksName(key)

	const hookIDGenerator = new idGenerator()

	const setters = {}
	const dispatchAll = (action) => {
		const nextValue = reducer(VisualizationManager[key], action)
		console.log(`[📖] ${key} updated: `, nextValue)
		VisualizationManager[variable] = nextValue
		Object.values(setters).forEach(setter => setter(nextValue))
		VisualizationManager[variableHooks].forEach(hook => hook(nextValue))
	}

	VisualizationManager[key] = initialValue
	VisualizationManager[variableDispatch] = dispatchAll
	VisualizationManager[variableHooks] = []
	VisualizationManager.vizStates[key] = initialValue

	function useVisualizationManagerReducer() {
		const [state, setState] = useState(VisualizationManager[key])
		const hookID = useConstant(hookIDGenerator.newID())

		useEffect(() => {
			setters[hookID] = setState
			return () => delete setters[hookID]
		}, [setState, hookID])

		return [state, dispatchAll]
	}

	return useVisualizationManagerReducer
}

/**
 * @name VizEffect
 * @category Visualization
 * 
 * @description A 'global useEffect' for VisualizationManager state. Use for variables created with {@link CreateVizState}
 * @param {function(any)} callback 
 * @param {string} key 
 * 
 * @see CreateVizState
 * @see CreateVizReducer
 * @todo Add example
 */
export function VizEffect (callback, key) {
	const variableHooks = hooksName(key)
	VisualizationManager[variableHooks].push(callback)
}

export const VIZ_MODES = {
    CONFIGURE: 'configure_mode',
    ANNOTATE: 'annotate_mode',
    ANALYZE: 'analyze_mode',
    REPORT: 'report_mode'
}


export const useMode = CreateVizState('mode', VIZ_MODES.ANNOTATE)
