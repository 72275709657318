const EDFFilterFunction = async (_, file) => {

    try {
        let filteredInputData = {
            allFiles: file
        };

        return filteredInputData;
    } catch (error) {
        console.log(error);
        alert(error);
        return null;
    }
};


export default EDFFilterFunction;
