import { createContext, useContext, useState, useEffect } from "react";
import { useEndpointProvider } from "./EndpointProvider";
import { useBackendLinksProvider } from '../Providers/BackendLinksProvider'
import { useStudiesProvider } from "./StudiesProvider";
import { useAuthProvider } from "./AuthProvider";
import { UpdateDispatcher } from "./UpdateDispatcher";


const SitesContext = createContext()

export const siteUpdates = {
    SITES_FILTERS: "sites_filters",
    USER_SITES: 'user_sites'
}

export const SitesProvider = ({ children }) => {  
    console.log("PROVIDER RENDER: SITES")

    const { LINKS } = useBackendLinksProvider()
    const [sites, setSites] = useState([])
    const [filters, setFilters] = useState({})

    const [userSites, setUserSites] = useState([])

    const endpointProvider = useEndpointProvider()
    const studiesProvider = useStudiesProvider()
    const authProvider = useAuthProvider()

    async function update(...updates) {
        const initState = {
            [siteUpdates.SITES_FILTERS]: { data: sites, filters },
            [siteUpdates.USER_SITES]: {data: userSites}
        }

        const dispatcher = new UpdateDispatcher(updates, initState, siteUpdates)

        dispatcher.dispatch(siteUpdates.SITES_FILTERS,
            () => endpointProvider.post(LINKS.ADMIN.SITES.GET_SITES,
                {study_id: studiesProvider.selectedStudyId},
                {return_all_sites: true}),
            data => {
                setSites(data?.data)
                setFilters(data?.filters)
            })

        dispatcher.dispatch(siteUpdates.USER_SITES,
            () => endpointProvider.post(LINKS.ADMIN.SITES.GET_SITES,
                {study_id: studiesProvider.selectedStudyId},
                {return_all_sites: false}),
            data => {
                setUserSites(data?.data)
            })
    }

    // Make sure we don't persist any data between logins.
    useEffect(() => {
        if (!authProvider.userIsLoggedIn && sites.length > 0) {
            setSites([])
        }
    }, [authProvider, sites.length])

    return (
        <SitesContext.Provider value={{ sites, filters, userSites, update }}>
            {children}
        </SitesContext.Provider>
    )
}

export const useSitesProvider = () => useContext(SitesContext)