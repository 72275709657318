import { ManualDownsampledPage } from "./ManualDownsampledPage";
import { TimeSeriesPageManager, TimeSeriesPageManagerConfig } from "./TimeSeriesPageManager";

export type ManualDownsampledPageManagerConfig = TimeSeriesPageManagerConfig & {
    resamplingPeriodSeconds: number
}

export class ManualDownsampledPageManager<ConfigType extends ManualDownsampledPageManagerConfig=ManualDownsampledPageManagerConfig> extends TimeSeriesPageManager<ManualDownsampledPage, ConfigType> {
	getPageMaker(): () => ManualDownsampledPage {
		return () => new ManualDownsampledPage()
	}

	updatePageProperties(page: ManualDownsampledPage, startTime: number, endTime: number, width: number) {
		page.updateProperties({
			startTime,
			endTime,
			width,
			modalityDataSources: this.config.modalityDataSources,
			socketId: this.config.windowId,
			patientId: this.config.patientId,
			patientIsAdmitted: this.config.patientIsAdmitted, 
			resamplingPeriodSeconds: this.config.resamplingPeriodSeconds,
			getDataQuerySocket: this.getDataQuerySocket
		})
	}
}