import React from "react";
import Whole from "./Pages/Whole/Whole";
import { BrowserRouter } from "react-router-dom"
import { AppProviders } from "./Providers/AppProviders";
import { ModalRenderer } from "./Providers/ModalsRenderer";

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TableProvider } from "./Providers/TableProvider";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        }
    }
})

const App = () => {
    return (
        <React.StrictMode>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <QueryClientProvider client={queryClient}>
                    <AppProviders>
                        <BrowserRouter>
                            <TableProvider>
                                <Whole />
                                <ModalRenderer />
                            </TableProvider>
                        </BrowserRouter>
                    </AppProviders>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </LocalizationProvider>
        </React.StrictMode>
    );
};

export default App;

