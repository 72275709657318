import { continuousArrToTimestamps, pageDataToBST } from "../Utils/Convenience"
import { Profiler, Stopwatch } from "../Utils/Profiling"
import VisualizationManager from "../VisualizationManager"




/**
 * @typedef {import("../VisualizationManager").Timestamp} Timestamp
 */


 export class Region {
    constructor (start_time, end_time, loaded) {
        this.id = regionId++
        this.start_time = start_time
        this.end_time = end_time   
        this.loaded = loaded     
    }

    static updateRegions(display){
        // we only need to draw the regions that are NOT loaded! loaded pages/regions are not added!
        var regions = []
        const pagesLoaded = display.neighborhood.filter(page => page.loaded).sort(function compareFn(a, b) {   
            return (a.start_time < b.start_time)? -1 : 1
        })  
        const length = pagesLoaded.length
        if(length > 0) {                     
            regions.push(new Region(display.min_start_time , pagesLoaded[0].start_time, false))     // the unloaded region from the display start to the beginning of the loaded pages
            var last_end_time = pagesLoaded[0].start_time
            pagesLoaded.forEach(page => { 
                if( last_end_time !== page.start_time){
                    regions.push(new Region(last_end_time, page.start_time, false)) // insert new unloaded region from the last loaded page to the begining of this page
                }
                last_end_time = page.end_time                        
            })                          
            regions.push(new Region(pagesLoaded[length - 1].end_time, display.max_end_time, false))  // the unloaded region from the end of the loaded region to the end of the display 
        }        
        else
            regions.push(new Region(display.min_start_time,display.max_end_time, false)) // nothing is loaded
            
        display.notLoadedRegions = regions
    }

    reset() {
        this.start_time = 0
        this.end_time = 0      
        this.loaded = false  
    }
}


export class Pages {
    // constructor (window) {
    //     const arr = []
    //     let start = start_time
    //     let end = start_time + page_time
    //     let idx = 0
    //     while (start < end_time) {
    //         const width = end < end_time ? page_width : Math.floor((end_time-start)*page_width/(page_time))
    //         arr.push(new Page(start, end < end_time ? end : end_time, idx++, Math.floor(Page.width_factor *width), modalities))
    //         start += page_time
    //         end += page_time
    //     }
    //     this.arr = arr

    //     this.loaded = []
    // }

    // reset() {
    //     const arr = []
    //     let start = start_time
    //     let end = start_time + page_time
    //     let idx = 0
    //     while (start < end_time) {
    //         const width = end < end_time ? page_width : Math.floor((end_time-start)*page_width/(page_time))
    //         arr.push(new Page(start, end < end_time ? end : end_time, idx++, Math.floor(Page.width_factor *width), modalities))
    //         start += page_time
    //         end += page_time
    //     }
    //     this.arr = arr

    //     this.loaded = []
    // }
}


var pageId = 0
var regionId = 0 
/**
 * @name Page
 * @class
 * @classdesc A container for graph data of a window_time width
 */
export class Page {
    
    /**
     * Create a Page
     * @param {Timestamp} start_time 
     * @param {Timestamp} end_time 
     */
    constructor (start_time, end_time, index, width, modalities) {
        this.id = pageId++

        this.index = index
        this.start_time = start_time
        this.end_time = end_time
        this.new_start_time = start_time
        this.new_end_time = end_time
        this.width = width
        this.modalities = modalities
        this.modalities_loaded = Object.fromEntries(VisualizationManager.modalities.map(modality => [modality, false]))
        this.modalities_loading = Object.fromEntries(VisualizationManager.modalities.map(modality => [modality, false]))
        this.data = {}
        this.compressedData = {}
        this.bst = {}

        this.points = []

        this.sw = new Stopwatch()
        this.next = null
    }

    get loaded () {
        return this.modalities.every(modality=>this.modalities_loaded[modality])
    }

    get loading () {
        return this.modalities.some(modality=>this.modalities_loading[modality])
    }

    static loadProfiler = new Profiler(100, 'Page.load (total)', 100)
    static serverProfiler = new Profiler(100, 'Page.load (server)', 100)

    


/* PAGE LOADING */
/* -------------------------------------------------------------------------*/
    static LOAD_EVENT_NAME = "render_modalities"
    static loadedPages = []
    static resets = 0

    // Modularized so that we can override it for custom analysis
    requestData(modalities) {
        VisualizationManager.socket.emit(Page.LOAD_EVENT_NAME, 
            VisualizationManager.patient_id, 
            VisualizationManager.file_id, 
            this.id, 
            modalities, 
            this.start_time, 
            this.end_time, 
            this.width, 
            time => {
                Page.serverProfiler.log(time)
                Page.loadProfiler.log(this.sw.click())
            }
        )
    }

    /**
     * Use to load a page's data from the file
     */
    async load () {
        return new Promise(
            resolve => {
                if (this.loaded || this.width === 0) {
                    resolve(this)
                    return
                }
                
                const modalities_to_load = this.modalities.filter(modality => !(this.modalities_loaded[modality] || this.modalities_loading[modality]))

                modalities_to_load.forEach(modality => this.modalities_loading[modality] = true)

                this.sw.click()

                const socketListenerId = `page-load-${this.id}`

                VisualizationManager.setSocketListener(socketListenerId, Page.LOAD_EVENT_NAME, 
                    socketResponse => {
                        if (socketResponse.page_id === this.id) {
                            this.receiveSocketResponse(socketResponse)
                            VisualizationManager.removeSocketListener(socketListenerId)
                            resolve(this)
                        }
                    })

                this.requestData(modalities_to_load)
            }
        )
    }


    /**
     * Loads data from Visualization socket response
     * @param {object} res 
     */
    receiveSocketResponse (res) {
        const received_modalities = Object.keys(res.pixels)
        received_modalities.forEach(modality => {
            this.modalities_loading[modality] = false
            this.modalities_loaded[modality] = true
        })

        this.loadPixels(res.pixels)
        this.runLoadHandlers()
        //Page.loadedPages.push(this)
    }

    loadPixels(pixels) {
        Object.keys(pixels).forEach(modality => {
            const values = pixels[modality][0]
            const continuous_array = pixels[modality][1] ?? []
            const timestamps = continuousArrToTimestamps(continuous_array)
            
            this.data[modality] = timestamps.map((timestamp, index) => (timestamp ? {value: values[index], timestamp: timestamp} : null))

            this.bst[modality] = pageDataToBST(this.data[modality])

            // const maxmins = this.maxmin[modality]
            // console.log(maxmins)
            // if (maxmins && maxmins.length > 0) {
            //     this.compressedData[modality] = timestamps.map((timestamp, index) => {
            //         const tup = timestamp ? [
            //             {value: values[index], timestamp: new Date(timestamp)},
            //             {value: maxmins[index][0], timestamp: new Date(timestamp)},
            //             {value: maxmins[index][1], timestamp: new Date(timestamp)},
            //         ] : [null]
            //         return tup
            //     })
            //     this.compressedData[modality] = this.compressedData[modality].flat()
            // } else {
            //     this.compressedData[modality] = null
            // }
        })
    }

    /* -------------------------------------------------------------------- */


    unload () {
        this.data = {}
        this.compressedData = {}
        this.bst = {}
        this.modalities_loaded = Object.fromEntries(VisualizationManager.modalities.map(modality => [modality, false]))
        this.modalities_loading = Object.fromEntries(VisualizationManager.modalities.map(modality => [modality, false]))
        
        this.runUnloadHandlers()
    }

    static loadHandlers = [
        page => {
            
        },
        page => {
            // d3.selectAll('.linegraph-line')
            //     .each(function(d, i, group) {
            //         const path = d3.select(this)
            //         const x = VisualizationManager.currentTransform.rescaleX(VisualizationManager.x)
            //         const y = d3.scaleLinear()
			// 			.range([0, LABEL_SETTINGS[d].graph_height])
			// 			.domain([LABEL_SETTINGS[d].min, LABEL_SETTINGS[d].max])

                    
            //         const line = d3.line()
            //             .defined((d) => d !== null)
            //             .x(d => x(d.timestamp))
            //             .y(d => y(d.value))
            //             .curve(d3.curveLinear)(page.data[d])

            //         if (!(d in page.data)) return
            //         path.attr('d', line)
            //     })
        }
    ]
    runLoadHandlers () { Page.loadHandlers.forEach(func => func(this))}

    static onload (func) { Page.loadHandlers.push(func)}
    
    

    static unloadHandlers = [
        page => {
            //remove(Page.loadedPages, page)
        }
    ]
    static onunload (func) { Page.unloadHandlers.push(func) }
    runUnloadHandlers () { Page.unloadHandlers.forEach(func => func(this))}

    static clearHandlers () {
        Page.loadHandlers = []
        Page.unloadHandlers = []
    }



    /**
     * Supremum / least-upper-bound function
     * @param {Timestamp} t 
     * @returns The smallest defined timestamp greater than or equal to t
     */
    sup(t, modality) {
        try {
            return this.bst[modality].ceiling(t)
        } catch {
            return this.data[modality]?.[this.data.length - 1]?.timestamp
        }
    }


    /**
     * Infimum / greatest-lower-bound function
     * @param {Timestamp} t 
     * @returns {Timestamp} The largest defined timestamp less than or equal to t
     */
    inf(t, modality) {
        try {
            return this.bst[modality].floor(t)
        } catch {
            return this.data[modality]?.[0]?.timestamp
        }
    }

    /**
     * Finds the nearest point within a given radius (eps) of a specific timestamp. Returns undefined if one cannot be found
     * @param {Timestamp} timestamp the timestamp to find the nearest point from
     * @param {Millis} eps the radius to search 
     * @returns the nearest defined point 
     */
    getNearestPoint(timestamp, eps = Infinity, modality) {
        const [inf, sup] = [this.inf(timestamp, modality), this.sup(timestamp, modality)]
        if (!inf || !sup) return undefined
        const closest_point = Math.abs(timestamp - inf) < Math.abs(timestamp - sup) ? inf : sup

        if (Math.abs(timestamp - closest_point) < eps) {
            return { "value": this.data[modality][this.bst[modality].get(closest_point)].value, "timestamp": closest_point }
        } else {
            return undefined
        }
    }

    static width_factor = 0.7
    static generatePages(start_time, end_time, page_time, page_width, modalities, display) {
        const pages = []
        let start = start_time
		let end = start_time + page_time 
		let idx = 0
        let prev = null
		while (start < end_time) {
            const width = end < end_time ? page_width : Math.floor((end_time-start)*page_width/(page_time))
            const new_page = new Page(start, end < end_time ? end : end_time, idx++, Math.floor(this.width_factor * width), modalities)
            pages.push(new_page)
            if (prev) prev.next = new_page
            prev = new_page
			start += page_time
			end += page_time
		}
        return pages
    }
}
