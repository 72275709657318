import { yupResolver } from "@hookform/resolvers/yup"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"
import ModalHeader from "../../../../Components/ModalHeader/ModalHeader"
import { ModalFooter } from "../../../../Constants/StyledComponents"
import MultivalueTextField from "../../../../Components/TextField/MultivalueTextField"
import MUITable from "../../../../Components/MUITable/MUITable"
import { useTableContext } from "../../../../Providers/TableProvider"
import { useEndpointProvider } from "../../../../Providers/EndpointProvider"
import { useModalProvider } from "../../../../Providers/ModalProvider"
import GridContainer from "../../../../Components/TextField/GridContainer"
import TableWrapper from "../../../../Components/TextField/TableWrapper"
import ModalScrollbar from "../../../../Components/ModalScrollbar/ModalScrollbar"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../../../../Components/MobergButton/MobergButton"
import { MobergFontSize } from "../../../../Components/MobergFont/MobergFont"
import { MobergTheme } from "../../../../Components/MobergThemes/MobergColors"
import { useBackendLinksProvider } from "../../../../Providers/BackendLinksProvider"

const projectColumns = [
	{ field: "identifier", flex: 0.4, headerName: "Project ID", visible: true },
	{ field: "name", flex: 0.4, headerName: "Name", visible: true },
	{ field: "bucket_name", flex: 0.4, headerName: "Repository", visible: true },
	{ field: "user_count", flex: 0.4, headerName: "No. users", valueGetter: params => params.row.user_count, visible: true },
	{ field: "patient_count", flex: 0.4, headerName: "No. patients", valueGetter: params => params.row.patient_count, visible: true },
]

const siteColumns = [
	{ field: "identifier", flex: 0.4, headerName: "Site ID", visible: true },
	{ field: "name", flex: 1, headerName: "Site name", visible: true },
	{ field: "description", flex: 1, headerName: "Description", visible: true },
	{ field: "location", flex: 1, headerName: "Location", visible: true },
	{ field: "user_count", flex: 0.4, headerName: "No. users", visible: true, valueGetter: params => params.row.user_count },
	{ field: "patient_count", flex: 0.4, headerName: "No. patients", visible: true, valueGetter: params => params.row.patient_count },
]

const roleColumns = [
	{ field: "name", flex: 1, headerName: "Role Name", visible: true },
	{ field: "description", flex: 1, headerName: "Description", visible: true },
]

export default function InviteUserModal() {
	const { setToastProps } = useTableContext()
	const queryClient = useQueryClient()
	const endpointProvider = useEndpointProvider()
	const { close } = useModalProvider()
	const { LINKS } = useBackendLinksProvider()

	const schema = yup
		.object({
			emails: yup.array().of(yup.string().email("Invalid email format").test("contains-at-and-com", "Invalid email format", (value) => value?.includes("@") && value?.includes(".com"))).min(1, "At least one email is required").required("Emails are required"),
		})
		.required()

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: { emails: [] },
		mode: "onChange",
	})

	const projectQueryProps = {
		queryKey: "projects",
		endpoint: LINKS.ADMIN.PROJECTS.GET_PROJECTS,
	}

	const siteQueryProps = {
		queryKey: "sites",
		endpoint: LINKS.ADMIN.SITES.GET_SITES,
	}

	const roleQueryProps = {
		queryKey: "roles",
		endpoint: LINKS.ADMIN.USER_MANAGEMENT.USERS.GET_ROLES,
	}

	const [selectedProjectRows, setSelectedProjectRows] = useState([])
	const preselectedProjectProps = { selectedRows: selectedProjectRows, setSelectedRows: setSelectedProjectRows }

	const [selectedSiteRows, setSelectedSiteRows] = useState([])
	const preselectedSiteProps = { selectedRows: selectedSiteRows, setSelectedRows: setSelectedSiteRows }

	const [selectedRoleRows, setSelectedRoleRows] = useState([])
	const preselectedRoleProps = { selectedRows: selectedRoleRows, setSelectedRows: setSelectedRoleRows }


	const inviteUsers = async body => endpointProvider.post(LINKS.ADMIN.USER_MANAGEMENT.USERS.INVITE_USERS, body)
	const inviteUserMutation = useMutation({
		mutationFn: body => inviteUsers(body),
		onError: error => {
			setToastProps({ isOpen: true, severity: "error", message: error })
		},
		onSuccess: () => {
			setToastProps({ isOpen: true, severity: "success", message: "User(s) invited successfully." })
		},
		onSettled: () => {
			queryClient.invalidateQueries("users")
		},
	})

	const [errorMessage, setErrorMessage] = useState('')

	const onSubmit = data => {
		if (selectedProjectRows.length === 0 || selectedSiteRows.length === 0 || selectedRoleRows.length === 0) {
			setErrorMessage("Please select at least one project, site and role")
			return
		}
		inviteUserMutation.mutate({
			...data,
			project_ids: selectedProjectRows,
			site_ids: selectedSiteRows,
			role_ids: selectedRoleRows,
		})
		setErrorMessage('')
		close()
	}

	useEffect(() => {
		if (Object.keys(errors).length > 0) {
			const error = errors[Object.keys(errors)[0]]
			if (Array.isArray(error)) {
				error.forEach(e => {
					if (e) {
						setErrorMessage(e?.message)
					}
				})
			} else {
				setErrorMessage(error?.message)
			}
		}
	}, [errors])

	return (
		<div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
			<ModalHeader headerText="Invite user" />
			<ModalScrollbar>
				<div style={{ maxWidth: "800px" }}>
					<h2>User Details</h2>
					<GridContainer>
						<MultivalueTextField
							inputId="invite-user-emails-id"
							label="Emails"
							tooltip="When a user is invited, they will be sent an email to this address with an invitation to join the workspace. They will also receive a separate email to create an account if they do not have one associated with this email."
							placeholder="admin@moberganalytics.com"
							autoFocus={true}
							control={control}
							name="emails"
							numberColumn={12}
						/>

						<TableWrapper inputId="invite-user-projects-id" label="Projects" tooltip="The projects that the user will be invited to.">
							<MUITable columns={projectColumns} {...projectQueryProps} preselectedProps={preselectedProjectProps} />
						</TableWrapper>

						<TableWrapper inputId="invite-user-sites-id" label="Sites" tooltip="The sites that the user will be invited to.">
							<MUITable columns={siteColumns} {...siteQueryProps} preselectedProps={preselectedSiteProps} />
						</TableWrapper>

						<TableWrapper inputId="invite-user-roles-id" label="Roles" tooltip="The roles that the user will be invited to.">
							<MUITable columns={roleColumns} {...roleQueryProps} preselectedProps={preselectedRoleProps} />
						</TableWrapper>
					</GridContainer>
				</div>
			</ModalScrollbar>
			<ModalFooter style={{ justifyContent: 'space-between', alignItems: 'center' }}>
				<h1 style={{ color: '#E54E58' }}>{errorMessage}</h1>
				<MobergButton
					theme={MobergTheme.BLUE}
					variant={MobergButtonVariant.FILLED}
					onClick={handleSubmit(onSubmit)}
					shape={MobergButtonShape.WIDE}
					fontSize={MobergFontSize.LARGE}
				>
					Invite
				</MobergButton>
			</ModalFooter>
		</div>
	)
}