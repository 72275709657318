import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTableContext } from "../../Providers/TableProvider"
import FilterListIcon from "@mui/icons-material/FilterList"
import SearchIcon from "@mui/icons-material/Search"
import { Input, InputAdornment } from "@mui/material"
import { MobergButton, MobergButtonShape, MobergButtonVariant } from "../MobergButton/MobergButton"
import { MobergTheme } from "../MobergThemes/MobergColors"
import { MdDelete } from "react-icons/md"
import { MobergIconSize } from "../MobergIcon/MobergIcon"
import { useModalProvider } from "../../Providers/ModalProvider"
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal"
import CustomColumnVisibilityButton from "./CustomColumnVisibilityButton"
import CustomColumnVisibilityMenu from "./CustomColumnVisibilityMenu"
import CustomViewMoreMenu from "./CustomViewMoreMenu"
import FileType from "../../Pages/Data/Visualize/UploadsSubpages/UploadWindows/FileType"

export default function TableToolbar(props) {
	const { createModal } = useModalProvider()
	const { rowSelectionModel, setRowSelectionModel, createButton, deleteButton, viewMoreButton, viewDomainsButton, openFilterPanel, exportButton, columnButton, filterButton, searchInput,
		setQueryOptions, nameForMultipleRows = "rows", setColumnsPopoverRef, columnsPanelAnchor, openColumnsPanel, isColumnsPanelOpen, setViewMorePopoverRef, viewMorePanelAnchor, openViewMorePanel, isViewMorePanelOpen, closeViewMorePopover } = props
	const { setToastProps } = useTableContext()
	const queryClient = useQueryClient()

	const deleteMutation = useMutation({
		mutationFn: deleteButton.onClick,
		onError: error => {
			setToastProps({ isOpen: true, severity: "error", message: error })
		},
		onSuccess: () => {
			if (!deleteButton.disableSuccessToast) {
				setToastProps({ isOpen: true, severity: "success", message: deleteButton.onSuccessMessage })
			}
			setRowSelectionModel([])
		},
		onSettled: () => {
			queryClient.invalidateQueries(deleteButton.invalidateQueryKey)
		},
	})

	const handleDelete = () => {
		if (deleteButton.disableConfirmation) {
			deleteMutation.mutate(rowSelectionModel)
			return
		}

		createModal(<ConfirmationModal
			title={`${deleteButton?.isUnload ? 'Unload' : 'Delete'} ${nameForMultipleRows}?`}
			description={deleteButton.confirmationDescription ? deleteButton?.confirmationDescription : `Are you sure you want to permanently delete these ${nameForMultipleRows}? This action cannot be undone.`}
			confirmButton={{
				text: deleteButton?.confirmationButtonText ? deleteButton?.confirmationButtonText : "Yes, delete",
				theme: MobergTheme.RED,
				onClick: () => deleteMutation.mutate(rowSelectionModel)
			}}
		/>)
	}

	function getUploadInputButton(fileType) {
		switch (fileType) {
			case FileType.CNS:
			case FileType.NATUS:
				return (
					<input
						webkitdirectory=""
						mozdirectory=""
						directory=""
						multiple=""
						type="file"
						id={`${createButton.label}PatientDirectory`}
						ref={createButton.inputRef}
						onChange={createButton.onChange}
						style={{ width: "112px", display: "none", whiteSpace: "nowrap" }}
					/>
				)
			case FileType.MEDICATIONS:
				return (
					<input
						type="file"
						id={`${createButton.label}PatientDirectory`}
						ref={createButton.inputRef}
						onChange={createButton.onChange}
						style={{ width: "112px", height: "40px", display: "none", whiteSpace: "nowrap" }}
						accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					/>
				)
			case FileType.EDF:
				return (
					<input
						type="file"
						id={`${createButton.label}PatientDirectory`}
						ref={createButton.inputRef}
						onChange={createButton.onChange}
						style={{ width: "112px", height: "40px", display: "none", whiteSpace: "nowrap" }}
						accept=".edf"
					/>
				)
			default:
				return
		}
	}

	const isToolbarEmpty = (
		!columnButton?.isShow
		&& !filterButton?.isShow
		&& !searchInput?.isShow
	)
	
	const isActionsBarEmpty = (
		!createButton?.isShow
		&& !deleteButton?.isShow
		&& !viewMoreButton?.isShow
		&& !viewDomainsButton?.isShow
	)

	return (
		<GridToolbarContainer style={{ display: "block", margin: 0 }}>
			<div style={{ paddingBottom: isActionsBarEmpty ? "0" : "8px" }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', gap: "8px", width: "fit-content" }}>
						{createButton?.isShow ? createButton?.fileType ?
							<MobergButton
								theme={MobergTheme.BLUE}
								variant={MobergButtonVariant.FILLED}
								shape={MobergButtonShape.WIDE}
								onClick={createButton.onClick}
							>
								{getUploadInputButton(createButton.fileType)}
								{createButton.title}
							</MobergButton> : (
								<MobergButton
									theme={MobergTheme.BLUE}
									variant={MobergButtonVariant.FILLED}
									onClick={createButton.onClick}
									disabled={createButton.disabled}
								>
									{createButton.title}
								</MobergButton>
							) : null}

						{deleteButton?.isShow && (
							<MobergButton
								theme={MobergTheme.RED}
								variant={MobergButtonVariant.FILLED}
								shape={MobergButtonShape.SQUARE}
								onClick={handleDelete}
								disabled={rowSelectionModel.length === 0}>
								{deleteButton.title ? deleteButton.title : <MdDelete size={MobergIconSize.REGULAR} />}
							</MobergButton>
						)}

						{exportButton?.isShow && <GridToolbarExport size="medium" sx={{ marginRight: '8px' }} />}
					</div>
					{viewMoreButton?.isShow && (
						<MobergButton
							theme={MobergTheme.BLUE}
							variant={MobergButtonVariant.OUTLINE}
							onClick={openViewMorePanel}>
							{viewMoreButton.title}
						</MobergButton>
					)}
					{viewDomainsButton?.isShow && (
						<MobergButton
							theme={MobergTheme.BLUE}
							variant={MobergButtonVariant.OUTLINE}
							onClick={viewDomainsButton.onClick}>
							{viewDomainsButton.title}
						</MobergButton>
					)}
				</div>
			</div>

			<div className='ToolBar-Button-Container' style={{ display: 'flex', padding: isToolbarEmpty ? "0" : "4px 8px", background: "white", borderRadius: "6px 6px 0 0", alignItems: "center", flex: 1 }}>

				{columnButton?.isShow && <CustomColumnVisibilityButton openColumnsPanel={openColumnsPanel} />}

				{filterButton?.isShow && (
					<MobergButton theme={MobergTheme.BLUE} onClick={openFilterPanel} shape={MobergButtonShape.SQUARE}>
						<FilterListIcon />
					</MobergButton>
				)}

				{searchInput?.isShow && (
					<div style={{ padding: "8px", flex: 1 }}>
						<Input
							id='MUI-Search-Bar'
							startAdornment={
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							}
							placeholder="Search"
							onChange={e => setQueryOptions({ ...props.queryOptions, search_value: e.target.value })}
							disableUnderline={true}
						/>
					</div>
				)}
			</div>

			<CustomColumnVisibilityMenu setPopoverRef={setColumnsPopoverRef} isOpen={isColumnsPanelOpen} anchorEl={columnsPanelAnchor} />
			<CustomViewMoreMenu setPopoverRef={setViewMorePopoverRef} isOpen={isViewMorePanelOpen} anchorEl={viewMorePanelAnchor} close={closeViewMorePopover} />

		</GridToolbarContainer >
	)
}
