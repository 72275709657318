import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from 'react-icons/md';
import bedIcon from '../../Components/SideBar/Icons/bed.svg'

import { FRONTEND_LINKS } from '../../Constants/FrontendLinks'

const DATA = {
    title: 'DATA',
    iconClosed: <FaIcons.FaPlus />,
    iconOpened: <FaIcons.FaMinus />,
    subNav: [

        {
            title: 'Patients',
            path: FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.MAIN,
            layout: 'data_profiling',
            icon: <img src={bedIcon} alt={'Database'} style={{ color: 'white', width: '25px', marginLeft: '-3px', marginRight: '-3px' }} />,
            index: 1,
            action_id: 'access_data_visualize',
            sub_page: false,

        },
        {
            path: FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.SUBPAGES.PATIENTS.MAIN,
            sub_page: true,
            index: 1,
        },
        {
            path: FRONTEND_LINKS.SUBPAGES.DATA.SUBPAGES.VISUALIZE.SUBPAGES.GROUPS.MAIN,
            sub_page: true,
            index: 1
        },


    ]
}

const WORKSPACE = {
    title: 'WORKSPACE',

    iconClosed: <FaIcons.FaPlus />,
    iconOpened: <FaIcons.FaMinus />,

    subNav: [
        {
            title: 'Projects',
            layout: 'admin_studies',
            path: FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.STUDIES.MAIN,
            icon: <MdIcons.MdLocalLibrary size={18} />,
            index: 10,
            action_id: 'access_admin_studies',
            sub_page: false,

        },
        {
            title: 'Sites',
            layout: 'admin_sites',
            path: FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.SITES.MAIN,
            icon: <MdIcons.MdApartment size={18} />,
            index: 11,
            action_id: 'access_admin_sites',
            sub_page: false,

        },
        {
            title: 'Users',
            layout: 'admin_user_management',
            path: FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.MAIN,
            icon: <FaIcons.FaUserCog size={18} />,
            index: 9,
            action_id: 'access_admin_user_management',
            sub_page: false,

        },
        {
            path: FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.SUBPAGES.USERS.SUBPAGES.ACTIVITY.MAIN,
            sub_page: true,
            index: 9,
        },
        {
            path: FRONTEND_LINKS.SUBPAGES.ADMIN.SUBPAGES.USER_MANAGEMENT.SUBPAGES.ROLES_AND_PERMISSIONS.MAIN,
            sub_page: true,
            index: 9,
        },
    ]

}

const sections = [DATA, WORKSPACE]

export const SidebarData = sections