import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHandler } from "../../../Hooks/useHandler";
import ReactDOM from "react-dom"
import styled from "styled-components";

const ModalContext = createContext({
    isOpen: false,
    setIsOpen: null,
    grayOverlay: false,
})

/**
 * @name Modal
 * @description
 * Wrapper that contains a modal
 * @param {} props 
 * @returns 
 */
export function Modal (props) {
    const { 
        grayOverlay=false,
    } = props

    const [isOpen, setIsOpen] = useState(false)

    return (<ModalContext.Provider value={{ isOpen, setIsOpen, grayOverlay }}>
        {props.children}
    </ModalContext.Provider>)
}

/**
 * Modal, but you provide the isOpen state variable
 * @param {} props 
 * @returns 
 */
 export function ControlledModal (props) {
    const { 
        isOpen, 
        setIsOpen,
        grayOverlay=false,
    } = props

    return (<ModalContext.Provider value={{ isOpen, setIsOpen, grayOverlay }}>
        {props.children}
    </ModalContext.Provider>)
}

export function ModalOpenButton (props) {
    const { children, onClick, ...rest} = props
    const { setIsOpen } = useContext(ModalContext)
    const openModal = ()=>setIsOpen(true)
    return <button {...rest}
        onClick={(event) => {onClick?onClick(event, openModal):openModal()}}
        >{children}</button> 
}

export function ModalCloseButton (props) {
    const { children, onClick, ...rest} = props
    const { setIsOpen } = useContext(ModalContext)
    const closeModal = ()=>setIsOpen(false)
    return <button {...rest}
        onClick={(event) => {onClick?onClick(event, closeModal):closeModal()}}
        >{children}</button> 
}

export function ModalToggleButton (props) {
    const { children, ...rest} = props
    const { setIsOpen } = useContext(ModalContext)
    return <button onClick={() => setIsOpen(prev => !prev)} {...rest}>{children}</button> 
}

export function useModal () {
    const { setIsOpen } = useContext(ModalContext)
    const open = () => setIsOpen(true)
    const close = () => setIsOpen(false)
    const toggle = () => setIsOpen(prev => !prev)
    return {open, close, toggle}
}

export function useModalEffect ( callback ) {
    const { isOpen } = useContext(ModalContext)

    useEffect(() => // we need to return
        callback(isOpen)
    , [callback, isOpen])
}

export function useOnModalDismiss(callback) {
    const hasOpened = useRef(false)
    const { isOpen } = useContext(ModalContext)

    if (isOpen) {
        hasOpened.current = true
    }

    if (hasOpened.current && !isOpen) {
        callback()
        hasOpened.current = false // We're done with this modal until we open it again.
    }
}

export function ModalContent (props) {
    const { isOpen, setIsOpen, grayOverlay } = useContext(ModalContext)
    const { disableAutoClosing, overlayContainer, children, id } = props
    const contentRef = useRef()
    const listener = useCallback((event) => {
        if (!disableAutoClosing // disableAutoClosing means click outside should have no effect
            && isOpen && !grayOverlay
            // parentElement is <Modal>...</Modal>
            && !contentRef.current.parentElement?.contains(event.target)) {
            // && !contentRef.current?.contains(event.target)) { // do not use this
            setIsOpen(false)
        }
    }, [disableAutoClosing, grayOverlay, isOpen, setIsOpen])

    useHandler("click", listener, {capture: true})
    useHandler("touchstart", listener, {capture: true})

    function renderOverlay(reactNode) {
        // If we pass in a container to render the overlay, render it there instead of within the parent.
        // This allows us to have nested modals with gray overlays that stack on top of the previous modal's content.
        if (overlayContainer) {
            return ReactDOM.createPortal(reactNode, overlayContainer)
        }
        return reactNode
    }

    function renderContent(reactNode) {
        // If there are nested overlays, we have to make sure we render the content of the modal in the content/overlay layer stack.
        if (grayOverlay) {
            return renderOverlay(reactNode)
        }
        return reactNode
    }

    return (
    <>
        {isOpen && grayOverlay && renderOverlay(<GrayOverlayAlt onClick={disableAutoClosing ? undefined : () => setIsOpen(false)}/>)}

        {renderContent(
            <div id={id} ref={contentRef} style={{display: isOpen ? "block" : "none"}}>
            {children}
        </div>)}
    </>
    )
}

export const GrayOverlayAlt = styled.div`
	background: #808080;
    z-index: 1;
	opacity: .8;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;