import { useCallback, useState, useContext } from "react"
import * as MdIcons from "react-icons/md";
import styled from "styled-components";
import { BlueButton, Columns, IconTextButton, Row, Rows } from "../../Utils/Styling";
import { VisualizationContext } from "../../Visualization";
import { AnnotationGroupAnnotationTable, } from "./AnnotationGroupAnnotationTable";
import { useEffect } from "react";
import { checkbox } from "../../../../Components/DataTable/SelectHandler"
import { InfoMessage } from "../../../../Constants/StyledComponents";
import PrimaryCTAButton, { buttonColor } from "../../../../Components/PrimaryCTAButton/PrimaryCTAButton";
import { useRecoilState, useRecoilValue } from "recoil"
import { useAnnotationService } from "../../../../Hooks/useAnnotationService";
import { annotationGroupsAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Annotations";
import { activeAnnotationSessionAtom, selectedAnnotationGroupAtom } from "../../../../Pages/Data/Visualize/DataReview/Atoms/Annotations";
import { authUpdates, useAuthProvider } from "../../../../Providers/AuthProvider";
import { useOnMount } from "../../../../Hooks/useOnMount";

export function AnnotationGroupEditor() {
	const [selectedAnnotationGroup, setSelectedAnnotationGroup] = useRecoilState(selectedAnnotationGroupAtom)
	const annotationGroups = useRecoilValue(annotationGroupsAtom)
	const [activeAnnotationSession, setActiveAnnotationSession] = useRecoilState(activeAnnotationSessionAtom)
	const [sessionName, setSessionName] = useState("")
	const [canRename, setCanRename] = useState(false)
	const [selectedAnnotations, setSelectedAnnotations] = useState([])
	const { deleteAnnotations, deleteAnnotationGroups, renameAnnotationGroup } = useAnnotationService()
	const authProvider = useAuthProvider()

	useOnMount(() => {
		authProvider.update(authUpdates.TOKEN, authUpdates.PERMISSIONS)
	})

	const handleRenameSession = () => {
		renameAnnotationGroup(selectedAnnotationGroup, sessionName)
			.then(() => {
				setCanRename(false); 
				setSelectedAnnotationGroup(prev => ({...prev, group_name: sessionName})); 
				setActiveAnnotationSession(prev => ({...prev, group_name: sessionName}))}
				)
			.catch(() => {console.log("ERROR")})
	}

	const handleDeleteSession = () => {
		const sessionSelectBox = checkbox(`row-${selectedAnnotationGroup.id}`)
		const restSessions = annotationGroups.filter(g => g.id !== activeAnnotationSession.id)
		if (selectedAnnotationGroup.id === activeAnnotationSession.id) {
			setActiveAnnotationSession(restSessions[0])
		}

		deleteAnnotationGroups([selectedAnnotationGroup])

		setSelectedAnnotationGroup(null)
		if (sessionSelectBox?.checked) {
			sessionSelectBox.click()
		} // release the selection if the session is selected
		// setState is asynchronous, so the length of selectedAnnotationGroups would not immediately got -1
	}

	const handleDeleteAnnotations = () => deleteAnnotations(selectedAnnotations.map(annotation => annotation.id))

	const handleCancel = e => {
		setSelectedAnnotationGroup(null)
	}

	useEffect(() => {
		setSessionName(selectedAnnotationGroup ? selectedAnnotationGroup.group_name : "")
		setCanRename(false)
	}, [selectedAnnotationGroup, setSessionName])

	const handleNameInput = useCallback(e => {
		if (e.target.value === selectedAnnotationGroup.group_name) {
			setCanRename(false)
		} else {
			setCanRename(true)
		}
		setSessionName(e.target.value)
	}, [selectedAnnotationGroup])

	return selectedAnnotationGroup ?
		(<AnnotationEditDiv spacing="5px" style={{ height: "fit-content", minHeight: "98%", overflowX: "unset", border: "0.5px solid #F0F0F0", borderRadius: "6px", margin: "7px 0", padding: "5px" }}>
			<Rows gap="10px">
				<Columns>
					<h1 style={{ margin: "3px 7px -3px 36px" }}>Session:</h1>
					<input type="text" onChange={handleNameInput} value={sessionName}
						style={{
							border: "solid 1px #0017333b", margin: "0 5px 0 9px", height: "30px",
							padding: "0 6px", borderRadius: "5px", width: "206px", outline: "#207dea"
						}}
					/>
					<PrimaryCTAButton
						buttonText="Rename"
						buttonStyle={{ height: "30px", width: "70px", fontSize: "14px", flex: "0.25" }}
						disabled={!canRename}
						color={buttonColor.blueFill}
						onClickFxn={handleRenameSession}
					/>
				</Columns>
				<Row>
					{/* <h1>Annotations: </h1> */}
					<AnnotationGroupAnnotationTable tableHeight="200px" handleSetSelectedAnnotations={setSelectedAnnotations} />
				</Row>
			</Rows>


			<Columns justifyContent="space-between" style={{ marginTop: "20px" }}>
				<Columns gap="10px" justifyContent="flex-end">
					<PrimaryCTAButton
						buttonText="Cancel"
						onClickFxn={handleCancel}
						color={buttonColor.redOutline}
						buttonStyle={{ height: "34px", padding: "0px 10px 0px 10px", marginRight: "0px" }}
					/>
					<PrimaryCTAButton
						buttonText="Delete annotations"
						onClickFxn={handleDeleteAnnotations}
						disabled={!(selectedAnnotations.length > 0) || !authProvider.permissions?.delete_annotations}
						color={buttonColor.redFill}
						buttonStyle={{ height: "34px", padding: "0px 10px 0px 10px", marginRight: "0px", opacity: !authProvider.permissions?.delete_annotations ? 0.5 : 1, pointerEvents: !authProvider.permissions?.delete_annotations ? 'none' : 'auto' }}
					/>

					<PrimaryCTAButton
						buttonText="Delete session"
						onClickFxn={handleDeleteSession}
						disabled={annotationGroups.length === 1 || !authProvider.permissions?.delete_annotation_group}
						color={buttonColor.redFill}
						buttonStyle={{ height: "34px", padding: "0px 10px 0px 10px", marginRight: "0px", opacity: !authProvider.permissions?.delete_annotation_group ? 0.5 : 1, pointerEvents: !authProvider.permissions?.delete_annotation_group ? 'none' : 'auto' }}
					/>
				</Columns>
			</Columns>

		</AnnotationEditDiv>)
		:
		(<InfoMessage style={{ height: "auto", width: "400px", padding: "6px", margin: "40px" }}>
			<p><strong>Info</strong>: Please select a session to view annotations.</p>
		</InfoMessage>)
}

function DropdownModalitySelector(props) {
	const {
		selectedModalities,
		setSelectedModalities,
		setEdited
	} = props

	const { modalities } = useContext(VisualizationContext)

	const [select, setSelect] = useState(Object.keys(modalities)[0])

	const handleSelect = useCallback(e => {
		setSelect(e.target.value)
	}, [])

	const handleAdd = useCallback(e => {
		setSelectedModalities(prev => prev.includes(select) ? prev : [...prev, select])
		setEdited(true)
	}, [select, setSelectedModalities, setEdited])

	const handleRemove = useCallback(modality => {
		setSelectedModalities(prev => [...prev].filter(i => i !== modality))
		setEdited(true)
	}, [setSelectedModalities, setEdited])

	return (<div style={{ width: "100%", overflowY: "scroll" }}>
		<h1>Modalities</h1>
		<BlueButton onClick={handleAdd}>
			Add
		</BlueButton>
		<select onChange={handleSelect}>
			{Object.keys(modalities).map(modality => <option key={`option_${modality}`} value={modality}>{modality}</option>)}
		</select>
		{selectedModalities.map(modality =>
			<span style={{ padding: "3px", borderRadius: "3px", border: "solid 1px black" }}>
				{modality}
				<IconTextButton onClick={e => {
					e.stopPropagation()
					handleRemove(modality)
				}}>
					<MdIcons.MdClose size={18} />
				</IconTextButton>
			</span>)}
	</div>)
}

function AnnotationTimeInput(props) {
	const { pointAnnotation,
		startTime,
		endTime,
		setStartTime,
		setEndTime
	} = props

	const handlePointTimeInput = useCallback((e) => {
		const time = (new Date(e.target.value)).getTime()
		setStartTime(time)
		setEndTime(time)
	}, [setStartTime, setEndTime])

	const handleStartTimeInput = useCallback((e) => {
		const time = (new Date(e.target.value)).getTime()
		setStartTime(time)
	}, [setStartTime])

	const handleEndTimeInput = useCallback((e) => {
		const time = (new Date(e.target.value)).getTime()
		setEndTime(time)
	}, [setEndTime])

	return (
		<Columns gap="10px" alignItems="center">
			<h1>Time: </h1>
			<ModalTextInput
				type={"datetime-local"}
				step={"0.001"}
				value={(new Date(startTime)).toISOString().slice(0, -1)}
				onChange={pointAnnotation ? handlePointTimeInput : handleStartTimeInput}
			/>
			{!pointAnnotation &&
				<>
					<h1>to</h1>
					<ModalTextInput
						type={"datetime-local"}
						step={"0.001"}
						value={(new Date(endTime)).toISOString().slice(0, -1)}
						onChange={handleEndTimeInput}
					/>
				</>
			}
		</Columns>)
}

const ModalTextInput = styled.input`
	min-width: 0;
	width: 0;
	flex: 0 1 100%;
	background: #ffffff;
	border: 2px solid #cccccc;
	border-radius: 5px;
	height: 22px;
	padding: 16px;
	::placeholder {
		font-family: "Source Sans Pro";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 150%;
		color: "gray";
	}
	:disabled {
		background-color: #DEDEDE;
	}
	:read-only {
		background-color: #DEDEDE;
	}
`;

const AnnotationEditDiv = styled(Rows)`
	h2 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 150%;
        color: #293241;
        margin-top: 5px;
		text-align: center;
    }
	h1 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #293241;
    }
`;