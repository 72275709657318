export let FRONTEND_LINKS = {}

FRONTEND_LINKS = {
    MAIN: '/',
    SUBPAGES: {
        RELEASE_CHANGELOGS: {
            MAIN: '/release_changelogs',
            SUBPAGES: {}
        },
        FORGOT_PASSWORD: {
            MAIN: '/forgot_password',
            SUBPAGES: {}
        },
        CHANGE_PASSWORD: {
            MAIN: '/change_password',
            SUBPAGES: {}
        },
        CREATE_ACCOUNT: {
            MAIN: '/create_account',
            SUBPAGES: {}
        },
        INACTIVE: {
            MAIN: '/inactive',
            SUBPAGES: {}
        },
        DATA: {
            MAIN: '/data',
            SUBPAGES: {
                SUMMARY: {
                    MAIN: '/data/trials',
                    SUBPAGES: {}
                },
                VISUALIZE: {
                    MAIN: '/data/visualize',
                    SUBPAGES: {
                        PATIENTS: {
                            MAIN: '/data/visualize/patients',
                            SUBPAGES: {
                                PATIENT: {
                                    MAIN: '/data/visualize/patients/patient',
                                    SUBPAGES: {}
                                },
                            }
                        },
                        GROUPS: {
                            MAIN: '/data/visualize/groups',
                            SUBPAGES: {
                                GROUP: {
                                    MAIN: '/data/visualize/groups/group',
                                    SUBPAGES: {}
                                },
                            } 
                        }   
                    },
                },
                UPLOAD: {
                    MAIN: '/data/visualize',
                    SUBPAGES: {
                        PATIENT: {
                            MAIN: '/data/visualize/patient',
                            SUBPAGES: {}
                        }
                    }
                },
                FORMS: {
                    MAIN: '/data/visualize',
                    SUBPAGES: {
                        PATIENT: {
                            MAIN: '/data/visualize/forms',
                            SUBPAGES: {}
                        }
                    }
                },
                FILES: {
                    MAIN: '/data/visualize',
                    SUBPAGES: {
                        PATIENT: {
                            MAIN: '/data/visualize/files',
                            SUBPAGES: {}
                        }
                    }
                }
            }
        },
        ADMIN: {    
            MAIN: '/admin',
            SUBPAGES: {
                USER_MANAGEMENT: {
                    MAIN: '/admin/user_management',
                    SUBPAGES: {
                        USERS: {
                            MAIN: '/admin/user_management/users',
                            SUBPAGES: {
                                ACTIVITY: {
                                    MAIN: '/admin/user_management/users/activity',
                                    SUBPAGES: {}
                                }
                            }
                        },
                        ROLES_AND_PERMISSIONS: {
                            MAIN: '/admin/user_management/roles_and_permissions',
                            SUBPAGES: {}
                        }
                    }
                },
                STUDIES: {
                    MAIN: '/admin/studies',
                    SUBPAGES: {}
                },
                SITES: {
                    MAIN: '/admin/sites',
                    SUBPAGES: {}
                },
                BUCKETS: {
                    MAIN:'/admin/buckets' ,
                    SUBPAGES: {}
                },
            }
        }
    }
}

