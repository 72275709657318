import { Selection, EnterElement, select } from "d3";
import { D3VisualizationRenderer } from "../../D3VisualizationRenderer";
import { D3SdDetection } from "./D3SdDetection";
import { D3SdDetectionConfigurationBuilder } from "./D3SdDetectionConfigurationBuilder";
import { D3VerticalLines } from "../../../D3/D3VerticalLines";
import { D3UTCAxis } from "../../../D3/D3UTCAxis";
import { D3EEGYAxis } from "../../EEGMontage/D3/D3BandScaleAxis";
import { SdDetectionConfig } from "../../../../Types/SdDetection";
import { EEGMontageConfig } from "../../../../Types/EEGMontageConfig";
import { D3AnnotationsWrapper } from "../../../D3/D3Annotations";
import { D3GraphsOverlay } from "../../TimeSeriesGraphGroup/D3/D3GraphsOverlay";
import { D3Timeline } from "../../../D3/Timeline/D3Timeline";
import { D3EEGCanvasesWrapper } from "./D3EEGCanvasesWrapper";

export class D3SdDetectionRenderer extends D3VisualizationRenderer<D3SdDetection, D3SdDetectionConfigurationBuilder> {
    private boundingBoxClassName: string = "d3-eeg-montage-bounding-box"
	private timelineContainerClassName: string = "d3-timeline-container"
	private timelineSpacing = 30

    // Children
    public xAxis?: D3UTCAxis
    public yAxis?: D3EEGYAxis
	public overlay?: D3GraphsOverlay
	public annotationsWrapper?: D3AnnotationsWrapper
	public eegCanvases?: D3EEGCanvasesWrapper
	public verticalLines?: D3VerticalLines

    // PUBLIC

    public getLastHoveredDate = () => this.overlay?.getLastHoveredDate()

    public viewTimesChanged = () => {
		this.xAxis?.render()
		this.timeline?.viewTimesChanged()
		this.verticalLines?.render()
		this.eegCanvases?.render()
		this.annotationsWrapper?.render()
		this.overlay?.render()
	}

    public onTimelineSliderDrag = () => {
		requestAnimationFrame(() => {
			this.xAxis?.render()
			this.annotationsWrapper?.render()
			this.verticalLines?.render()
			this.eegCanvases?.render()
		})
	}

	public updateChildren = () => {
		const svg = select(this.visualization.root).select("svg").node() as SVGSVGElement

        this.xAxis?.updateConfig(this.configBuilder.getXAxisConfig())
        this.yAxis?.updateConfig(this.configBuilder.getYAxisConfig())
		this.timeline?.updateConfig(this.configBuilder.getTimelineConfig())
		this.verticalLines?.updateConfig(this.configBuilder.getVerticalLinesConfig())
		this.overlay?.updateConfig(this.configBuilder.getOverlayConfig(svg))
		this.eegCanvases?.updateConfig(this.configBuilder.getEEGCanvasesConfig())
		this.annotationsWrapper?.updateConfig(this.configBuilder.getAnnotationsConfig())
    }

    // PROTECTED

	protected canRender(): boolean {
		return this.visualization.graphBoundingBox.width > 0 && this.visualization.graphBoundingBox.height > 0
	}

    protected enter = (newElements: Selection<EnterElement, any, any, any>) => {
		const svg = newElements
            .append("svg")
            .attr("class", this.className)
			.attr("width", "100%")
			.attr("height", "100%")

        const boundingBox = svg
			.append("g")
			.attr("class", this.boundingBoxClassName)
			.attr("transform", `translate(${this.visualization.graphBoundingBox.x}, ${this.visualization.graphBoundingBox.y})`)

        boundingBox.each((config, index, nodes) => this.createBoundingBoxChildren(config, index, nodes, svg.node() as SVGSVGElement))

		const timelineContainer = svg
			.append("g")
			.attr("class", this.timelineContainerClassName)
			.attr("transform", `translate(${this.visualization.graphBoundingBox.x}, ${this.visualization.graphBoundingBox.y + this.visualization.graphBoundingBox.height + this.timelineSpacing})`)

		timelineContainer.each(this.createTimeline)

		return svg
	}

	protected update = (updatedSVG: Selection<any, any, any, any>): Selection<any, any, any, any> => {
		const svg = updatedSVG

		const timelineContainer = svg.select("." + this.timelineContainerClassName)
		timelineContainer.attr("transform", `translate(${this.visualization.graphBoundingBox.x}, ${this.visualization.graphBoundingBox.y + this.visualization.graphBoundingBox.height + this.timelineSpacing})`)

		svg.select("clipPath")
			.select("rect")
			.attr("width", this.visualization.graphBoundingBox.width)
			.attr("height", this.visualization.graphBoundingBox.height)

		this.renderChildren()

		return updatedSVG
	}
    
    // PRIVATE

    private createBoundingBoxChildren = (config: SdDetectionConfig, index: number, nodes: ArrayLike<SVGGElement>, svg: SVGSVGElement) => {
        const root = nodes[index]

		this.eegCanvases = new D3EEGCanvasesWrapper(root, this.configBuilder.getEEGCanvasesConfig(), this.visualization.timeSeriesPageManager, this.visualization.reactCallbacks)
        this.verticalLines = new D3VerticalLines(root, this.configBuilder.getVerticalLinesConfig())
        this.xAxis = new D3UTCAxis(root, this.configBuilder.getXAxisConfig(), this.visualization.reactCallbacks)
        this.yAxis = new D3EEGYAxis(root, this.configBuilder.getYAxisConfig(), this.visualization.reactCallbacks)

        select(root)
            .append("clipPath")
            .attr("id", this.configBuilder.getGraphClipPathId())
            .append("rect")
            .attr("width", this.visualization.graphBoundingBox.width)
            .attr("height", this.visualization.graphBoundingBox.height)

        this.overlay = new D3GraphsOverlay(root, this.configBuilder.getOverlayConfig(svg), this.visualization.reactCallbacks)
        this.annotationsWrapper = new D3AnnotationsWrapper(root, this.configBuilder.getAnnotationsConfig(), this.visualization.reactCallbacks)
    };

	private createTimeline = (config: EEGMontageConfig, index: number, nodes: ArrayLike<SVGGElement>) => {
		const root = nodes[index]
		this.timeline = new D3Timeline(root, this.configBuilder.getTimelineConfig(), this.visualization.timeSeriesPageManager, this.visualization.reactCallbacks)
	}

	private renderChildren() {
		this.xAxis?.render()
        this.yAxis?.render()
		this.timeline?.render()
		this.verticalLines?.render()
		this.overlay?.render()
		this.eegCanvases?.render()
		this.annotationsWrapper?.render()
	}

}