import * as d3 from 'd3'
import _ from "lodash"
import VisualizationManager from '../../VisualizationManager'
import { px } from "../../Utils/Convenience"
import { ADD_HTML, PLAY_HTML, PAUSE_HTML, IconButtonStyle, LEGEND_WIDTH, YAXIS_WIDTH, GRAPH_PADDING, TIMELINE_VIEWBOX_PADDING } from "../Constants"
import { DisplayTimesEvent } from '../../Display'
import { Region } from "../../Variables/Page"
import { calculateTimedTicksAndFormat, isMidnight } from '../../Ticks'
import { createTimelineTooltip, showTimelineTooltip, hideTimelineTooltip, updateTimelineTooltipPosition } from './TimelineTooltip'

const RENDER_PAGE_BOUNDARIES = true
const SLIDER_HANDLE_WIDTH = 8
const _1X_SPEED = 50

function getTimelineSlider(display) {
    return d3.select(`#timeline-slider-d${display.id}`)
}

function getTimelineSliderHandle(display, side) {
    return d3.select(`#timeline-slider-handle-${side}-d${display.id}`)
}

function moveTimelineSlider(display, x1, x2) {
    getTimelineSlider(display)
        .attr("x", x1)
        .attr("width", Math.max(1, x2 - x1))

    getTimelineSliderHandle(display, "start")
        .attr("x", x1 - SLIDER_HANDLE_WIDTH/2)

    getTimelineSliderHandle(display, "end")
        .attr("x", x2 - SLIDER_HANDLE_WIDTH/2)
}

function switchTimelineSliderHandles(display) {
    getTimelineSliderHandle(display, "start")
        .attr("id", `timeline-slider-handle-end-d${display.id}`)

    getTimelineSliderHandle(display, "end")
        .attr("id", `timeline-slider-handle-start-d${display.id}`)
}

const timelineSliderResizeHandler = d3.drag()
    .on("start", (dragEvent, display) => {
        const node = dragEvent.sourceEvent.target
        const side = node.id.includes("handle-end") ? "end" : "start"
        display.resizeTimelineSlider = {node, side}
    })
    .on("drag", (dragEvent, display) => {
        if (display.hasEEG) {
            return
        }

        const side = display.resizeTimelineSlider.side
        const initialTime = side === "start" ? display.start_time : display.end_time
        const initialX = display.timelineScale(initialTime)
        const [minimumX, maximumX] = display.timelineScale.range()

        // Preserve the initial offset to the slider on left overdrag.
        if ((dragEvent.x < minimumX && side === "start" && display.start_time === VisualizationManager.file_start)) {
            return
        }

        // Preserve the initial offset to the slider on right overdrag.
        if (dragEvent.x > maximumX && side === "end" && display.end_time === VisualizationManager.file_end) {
            return
        }

        const newX = Math.min(maximumX, Math.max(minimumX, initialX + dragEvent.dx))

        const newTime = display.timelineScale.invert(newX)

        if (side === "start" && newTime > display.end_time) {
            switchTimelineSliderHandles(display)
            display.resizeTimelineSlider.side = "end"
            const pageSizes = VisualizationManager.pageSize
            pageSizes[display.id] = new Date(newTime).getTime() - display.end_time
            VisualizationManager.setPageSize(structuredClone(pageSizes))
            return display.call("settimes", new DisplayTimesEvent(display.end_time, newTime))
        } 
        
        if (side === "end" && newTime < display.start_time) {
            switchTimelineSliderHandles(display)
            display.resizeTimelineSlider.side = "start"
            const pageSizes = VisualizationManager.pageSize
            pageSizes[display.id] = display.start_time - new Date(newTime).getTime()
            VisualizationManager.setPageSize(structuredClone(pageSizes))
            return display.call("settimes", new DisplayTimesEvent(newTime, display.start_time))
        }

        if (side === "end") {
            const pageSizes = VisualizationManager.pageSize
            pageSizes[display.id] = new Date(newTime).getTime() - display.start_time
            VisualizationManager.setPageSize(structuredClone(pageSizes))
            display.call("settimes", new DisplayTimesEvent(display.start_time, newTime))
        } else if (side === "start") {
            const pageSizes = VisualizationManager.pageSize
            pageSizes[display.id] = display.end_time - new Date(newTime).getTime()
            VisualizationManager.setPageSize(structuredClone(pageSizes))
            display.call("settimes", new DisplayTimesEvent(newTime, display.end_time))
        }

    })
    .on("end", (dragEvent, display) => {
        display.resizeTimelineSlider = undefined
        if (display.window_page_ratio > 1.0 || display.window_page_ratio < 1.0) {
            display.resetPages()
        }
    })

const timelineSliderDragHandler = d3.drag()
    .on("start", (dragEvent, display) => {
        const pointerOffset = dragEvent.x

        const [startX, endX] = display.timelineScale.range()
        const startOffset = display.timelineScale(display.start_time) - startX
        const endOffset = endX - display.timelineScale(display.end_time)

        display.timelineDragBounds = {
            lower: pointerOffset - startOffset,
            upper: pointerOffset + endOffset
        }

        getTimelineSlider(display).attr("fill", "rgba(32, 125, 234, 0.3)")
        showTimelineTooltip(display)
    })
    .on("drag", (dragEvent, display) => {
        const dx = dragEvent.dx

        const selectionX1 = display.timelineScale(display.start_time)
        const selectionX2 = display.timelineScale(display.end_time)
        const [minimumTime, maximumTime] = display.timelineScale.domain()
        const [minimumX, maximumX] = display.timelineScale.range()
        const windowSizePixels = selectionX2 - selectionX1

        let newSelectionX1 = selectionX1 + dx
        let newSelectionX2 = selectionX2 + dx

        let newStartTime = display.timelineScale.invert(newSelectionX1)
        let newEndTime = display.timelineScale.invert(newSelectionX2)

        // Preserve the initial offset to the slider on left overdrag.
        if ((dragEvent.x < display.timelineDragBounds.lower && selectionX1 === minimumX)) {
            return
        }

        // Preserve the initial offset to the slider on right overdrag.
        if (dragEvent.x > display.timelineDragBounds.upper && selectionX2 === maximumX) {
            return
        }

        // Lock the slider to the beginning on overdrag.
        if (newStartTime < minimumTime) {
            newSelectionX1 = minimumX
            newSelectionX2 = newSelectionX1 + windowSizePixels
        } 

        // Lock the slider to the end on overdrag.
        if (newEndTime > maximumTime) {
            newSelectionX2 = maximumX
            newSelectionX1 = newSelectionX2 - windowSizePixels
        }

        newStartTime = display.timelineScale.invert(newSelectionX1)
        newEndTime = display.timelineScale.invert(newSelectionX2)
        const newSettings = structuredClone(VisualizationManager.toolbarSettings)

        VisualizationManager.displays.forEach(displayValue => {
            if (displayValue.id === display.id) {
                newSettings[displayValue.id] = { ...newSettings[displayValue.id], 'windowTime': new Date(newStartTime).getTime()}
            } else {
                newSettings[displayValue.id] = { ...newSettings[displayValue.id], 'windowTime': displayValue.start_time}
            }
        })
        
        VisualizationManager.setToolbarSettings(newSettings)

        // Update the stuff
        display.call('settimes', new DisplayTimesEvent(newStartTime, newEndTime, 'timeline'))
        moveTimelineSlider(display, newSelectionX1, newSelectionX2)
    })
    .on("end", (dragEvent, display) => {
        getTimelineSlider(display).attr("fill", "rgba(32, 125, 234, 0.2)")
        hideTimelineTooltip(display)
    })

function getNewTimelineAxis(display) {
    const [ticks, tickFormat] = calculateTimedTicksAndFormat(display.min_start_time, display.max_end_time)

    return d3.axisBottom(display.timelineScale)
        .ticks(ticks)
        .tickFormat(date => {
            return isMidnight(date) ? d3.timeFormat('%m/%d/%Y')(date) : d3.timeFormat(tickFormat)(date)
        })
}

function createSliderDragHandle(selection, side) {
    selection.append('rect')
        .attr("id", display => `timeline-slider-handle-${side}-d${display.id}`)
        .attr("height", 34)
        .attr("width", SLIDER_HANDLE_WIDTH)
        .attr("x", display => side === "start" ? -SLIDER_HANDLE_WIDTH/2 : display.timelineScale(display.end_time) - SLIDER_HANDLE_WIDTH/2)
        .attr("y", 10)
        .attr("fill", "rgba(0,0,0,0)")
        .attr("cursor", display => display.hasEEG ? "move" : "ew-resize")
        .each((display, i, nodes) => {

            const nodeSelection = d3.select(nodes[i])

            // For EEG displays, the window size is really small and we don't want to allow users to change the size.
            // Instead, we're replacing the resize handler with the drag handler.
            if (display.hasEEG) {
                nodeSelection.call(timelineSliderDragHandler)
            } else {
                nodeSelection.call(timelineSliderResizeHandler)
            }
        })
}

function createTimelineSlider(selection) {
    selection.append('rect')
        .attr("id", display => `timeline-slider-d${display.id}`)
        .attr("x", display => display.timelineScale(display.start_time))
        .attr("y", 10)
        .attr("width", display => {
            const x1 = display.timelineScale(display.start_time)
            const x2 = display.timelineScale(display.end_time)
            return Math.max(0, x2 - x1)
        })
        .attr("height", 34)
        .attr("fill", "rgba(32, 125, 234, 0.2)")
        .attr("fill", "rgba(32, 125, 234, 0.13)")
        .attr("rx", 2)
        .attr("ry", 2)
        .attr("stroke-width", "2")
        .attr("stroke", "#207DEA")
        .attr("cursor", "move")
        .call(timelineSliderDragHandler)

    selection
        .call(selection => createSliderDragHandle(selection, "start"))
        .call(selection => createSliderDragHandle(selection, "end"))
}

function createTimelineDragOverlay(selection) {
    selection.append('rect')
        .attr("id", display => `timeline-drag-overlay-d${display.id}`)
        .attr("x", 0)
        .attr("y", 10)
        .attr("width", display => display.timelineScale.range()[1])
        .attr("height", 34)
        .attr("cursor", "crosshair")
        .attr("fill-opacity", 0)
        .call(timelineSliderDragHandler)
}

export function createTimeline (selection) {  
    const bottomBar = selection.append('div')
        .attr('class', 'container-timeline')
        .style('height', '60px')
        .style('display', 'flex')

    const timeline_settings = bottomBar.append('div')
        .attr('class', 'timeline-settings')
        .style('display', 'flex')
        .style('justify-content', 'space-evenly')
        .style('align-items', 'center')
        .style('width', `${YAXIS_WIDTH}px`)

    timeline_settings.append('button')
        .attr('style', IconButtonStyle)
        .html(ADD_HTML)
        .on('click', function(e, display) {
            display.graphs.newGraph()
        })
    
    
    const timelineContainer = bottomBar
        .append("div")
        .style("position", "relative") // set for tooltip positioning.

    const timeline_svg = timelineContainer
        .append('svg')
        .attr('id', d => `timeline-data-d${d.id}`)
        .attr('class', 'timeline-data')
        .attr('height', '60px')
        .attr('viewBox', d => `${-TIMELINE_VIEWBOX_PADDING} 0 ${d.inner_width + 2*TIMELINE_VIEWBOX_PADDING} 60`)
        .attr('preserveAspectRatio', 'none')
        .style('flex', '0 1 100%')

    timelineContainer.call(createTimelineTooltip)

    const timeline_pages = timeline_svg.append('g')
        .attr('class', 'timeline-pages')

    const timeline_annotations = timeline_svg.append('g')
        .attr('class', 'timeline-annotations')

    const timeline_legend = bottomBar.append('div')
        .attr('class', 'timeline-legend')
        .style('display', 'flex')
        .style('align-items', 'center')
        .style('justify-content', 'center')
        .style('width', px(LEGEND_WIDTH))

    const REFRESH_RATE = 20 // every 20 milliseconds - about 50hz if it can keep up

    const playButton = timeline_legend.append('playButton')    
        .attr("id", display => `playButton${display.id}`)
        .attr('style', IconButtonStyle)
        .style('margin-bottom', px(10))    
        .style('margin-right', px(30))     
        .style('align-items', 'center')
        .style('justify-content', 'center')
        .html(PLAY_HTML)
        .on('click', function(e, display) {
            var target = e.target;
            if((display.playing == null) || (display.playing === false)) {
                
                display.playing = true       
                playButton.html(PAUSE_HTML)
                this.interval = setTimeout(function play() {     
                    const  increment = display.playSpeed?display.playSpeed * _1X_SPEED : _1X_SPEED
                    display.call("settimes", new DisplayTimesEvent(display.start_time + increment, display.end_time + increment))    
                    if(display.playing === true)
                        setTimeout(play, REFRESH_RATE)
                }, REFRESH_RATE )    
            }     
            else{
                clearTimeout(this.interval)
                display.playing = false                    
                playButton.html(PLAY_HTML)
            }                                    
        })

    const playSpeed = [{label: '1', value:'1'}, {label:  '2', value:'2'},  {label: '3', value:'3'},  {label:'4', value:'4'},  {label: '5', value:'5'},  {label: '6', value:'6'},  {label: '7', value:'7'},  {label:'8', value:'8'},  {label:'9', value:'9'}, {label:'10', value:'10'}]
    
    const speedSelect =  timeline_legend.append('select')    
        .attr("id", display => `speedSelect-${display.id}`)
        .style('width', '35%')
        .style('border-radius', '6px')
        .style('border', '0.5px solid #B6B6B6')
        .style('font-family', 'Source Sans Pro')
        .style('font-style', 'normal')
        .style('font-weight', '400')
        .style('font-size', '12px')
        .style('line-height', '150%')       
        .style('text-align', 'center')
        .on('change', function(e, display) {
            display.call('configEEG', 'speed', e.target.value)                      
        })
        .selectAll('option')
        .data(playSpeed)
        .join('option')
        .text(d => d.label)
        .attr('value', d=>d.value)

    const timeline_rect = timeline_svg.append("rect")
            .attr("id", d => `timeline-rect-d${d.id}`)
            .attr("x", 0)
            .attr("y", 10)
            .attr("width", d => d.inner_width)
            .attr("height", 34)
            .attr("stroke", "#B6B6B6")
            .attr("fill", "none")
            .attr("stroke-width", 1)
            .attr("rx", 4)
            .attr("ry", 4)

    timeline_svg.each(function(d, i, nodes){
        d.timeline_axis = getNewTimelineAxis(d)

        d3.select(this).append('g')
            .attr("id", `timeline-d${d.id}`)
            .attr('transform', 'translate(0, 44)')
            .call(d.timeline_axis)
            .call(g => g.select('.domain').remove())
        
        updatePages(d)
        renderAnnotations(d)

        d.on('settimes.timeline', function (event) {
            let { start_time, end_time, source } = event
            
            start_time = Math.max(start_time, VisualizationManager.file_start)
            end_time = Math.min(end_time, VisualizationManager.file_end)

            const x1 = d.timelineScale(start_time)
            const x2 = d.timelineScale(end_time)

            if (source === "timeline") {
                return
            }

            moveTimelineSlider(d, x1, x2)
            
            // Update Timeline Axis and width
            d.timeline_axis = getNewTimelineAxis(d)
            d3.select(`#timeline-d${d.id}`)
                .call(d.timeline_axis)
                .call(g => g.select('.domain').remove())

            d3.select(`#timeline-rect-d${d.id}`)
                .attr("width", d.inner_width)

            d3.select(`#timeline-drag-overlay-d${d.id}`)
                .attr("width", d.inner_width)

            d3.select(`#timeline-data-d${d.id}`)
                .attr("viewBox", `${-TIMELINE_VIEWBOX_PADDING} 0 ${d.inner_width + 2*TIMELINE_VIEWBOX_PADDING} 60`)
        })
    })    
        
    timeline_svg.call(createTimelineDragOverlay)
    timeline_svg.call(createTimelineSlider)

    timeline_svg.on('dblclick', (event, display) => {
        //display.call('settimes', new DisplayTimesEvent(VisualizationManager.file_start, VisualizationManager.file_end))
        //display.resetPages()
        //updatePages(display)
        //display.loadNeighborhood()    
    })
}

 
export function updatePage(display, page) {
    // TODO update loaded regions here avoing filtering and sorting of the loaded pages
    //const g_pages = display.content.select('.timeline-pages')
    //g_pages.select(`#rect-page${page.id}`).attr("fill", page.loaded ? "#FFFFFF" : "#DDDDDD")
    updatePages (display)
}


export function updatePages(display) {

    Region.updateRegions(display)
    const g_pages = display.content.select('.timeline-pages')
    g_pages.selectAll('.not-loaded-region')
        .data(display.notLoadedRegions, region => region.id)
        .join(
            enter => enter.append('rect')
                .attr('class', 'not-loaded-region')
                .attr('x', r => display.timelineScale(r.start_time))
                .attr('y', 10)
                .attr('width',  r => display.timelineScaleRelative(r.end_time - r.start_time))
                .attr('height', "34px")
                .attr('fill', r => r.loaded ? "#FFFFFF" : "#DDDDDD")
                .attr('stroke', RENDER_PAGE_BOUNDARIES ? "#B6B6B6" : "none"),
            update => update.attr('x', r => display.timelineScale(r.start_time))
                            .attr('width',  r => display.timelineScaleRelative(r.end_time - r.start_time)),
            exit => exit.remove()      
        )
}

export function renderAnnotations(display) {
    const g_annotations = display.content.select('g.timeline-annotations')
    g_annotations.selectAll('rect')
        .data(Object.values(VisualizationManager.annotations), a => a.id)
        .join(
            enter => enter.append('rect')
                .attr('class', 'annotation')
                .attr('x', a => display.timelineScale(a.start_time))
                .attr('y', GRAPH_PADDING / 2)
                .attr('width',  a => display.timelineScaleRelative(a.end_time - a.start_time))
                .attr('fill', 'red')
                .attr('height', '34px')
                .attr('opacity', 0.2),
            update => update
                .attr('fill', a => a.color)
                .attr('x', a => display.timelineScale(a.start_time))
                .attr('width',  a => display.timelineScaleRelative(a.end_time - a.start_time)),
            exit => exit.remove()
        )
}

function timelineBrushStart (e, display) {
    if (!e.sourceEvent) return;

    d3.select(this).selectAll('.selection')
        .attr("fill", "rgba(32, 125, 234, 0.5)")
}

function timelineBrushing (e, display) {
    if (!e.sourceEvent) return
    const start = e.selection[0]
    const end = e.selection[1]
    const start_time = display.timelineScale.invert(start)
    const end_time = display.timelineScale.invert(end)

    display.call('settimes', new DisplayTimesEvent(start_time, end_time, 'timeline'))

    d3.select(this).selectAll('.selection')
        .attr("fill", "rgba(32, 125, 234, 0.5)")
}

function timelineBrushEnd (e, display) {
    if (!e.sourceEvent) return
    if (display.window_page_ratio > 1.0 || display.window_page_ratio < 1.0) {
        display.resetPages()
    }

    display.loadNeighborhood()

    d3.select(this).selectAll('.selection')
        .attr("fill", "rgba(32, 125, 234, 0.13)")
}

