import VisualizationManager from '../VisualizationManager';


// checks if two intervals intersect
export const intersects = (x1, y1, x2, y2) => (y1 >= x2) && (y2 >= x1)

// 'clips off' the ends of (start, end) that fall outside (clip_start, clip_end)
export function clip_interval (start, end, clip_start, clip_end) {
    if (start >= clip_end) return [clip_end, clip_end]
    if (end < clip_start) return [clip_start, clip_start]
    const start_ret = start <= clip_start ? clip_start : start;
    const end_ret = end >= clip_end ? clip_end : end;

    return [start_ret, end_ret]
}


export function windowXtoTimestamp(x) {
    return (VisualizationManager.graph_time())*(x)/ VisualizationManager.width + VisualizationManager.graph_start()
}


export function timestampToWindowX(timestamp) {
    return (timestamp - VisualizationManager.graph_start())*VisualizationManager.width /(VisualizationManager.graph_time())
}


export function timestampToTimelineX(t) {
    return ((t - VisualizationManager.file_start) / VisualizationManager.file_time()) * VisualizationManager.width
}


export function add(arr, item) {
    if (!arr.includes(item)) arr.push(item)
}

export function merge (target, source) {
    source.forEach(item => add(target, item));
}


export function remove(arr, item) {
    return arr.filter(i => i !== item)
}


export const hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export function continuousArrToTimestamps(continuous_array) {
    const segments = continuous_array.map((segment) => {
        const start_timestamp = segment[2] / 1000
        const end_timestamp = segment[3] / 1000
        const time = end_timestamp - start_timestamp
        const num_pts = segment[1] - segment[0]
        const timestamp_arr = []
        for (let i = 0; i < num_pts; i++) 
            timestamp_arr.push(start_timestamp + (time * i) / (num_pts))
        return timestamp_arr
    })
    const out = []
    for (const seg of segments){
        out.push(...seg)
        out.push(null)
    }
        
    return out
}

const BST = require('red-black-bst');
export function pageDataToBST (pageData) {
    const bst = new BST()
    for (let i = 0; i < pageData.length; i++) {
        if (pageData[i]) bst.put(pageData[i].timestamp, i)
    }
    return bst
}

// lcg
function* idGeneratorFunction(seed) {
    const mod = 2147483648
    const multiplier = 1103515245
    const increment = 12345
    
    let x = seed
    while (true) {
        x = (x*multiplier + increment) % mod
        yield x
    }
}

/**
 * Simple function that generates ids
 */
export class idGenerator {
    constructor () {
        this.generator = idGeneratorFunction(Math.floor(Math.random()*100))
    }
    newID () {
        return this.generator.next().value.toString()
    }
}

export function px (n) { return `${n}px` }

export function invertedObject (obj){
    const ret = {};
    for(var key in obj){
      ret[obj[key]] = key;
    }
    return ret;
}