import VisualizationManager, { CreateVizState, VizEffect } from "./VisualizationManager"
import * as d3 from 'd3'
import { createTimeline, updatePage, updatePages } from "./Viewport/Components/Timeline"
import { createXAxis, WINDOW_TIME_PRESETS } from "./Viewport/Components/XAxis"
import { CONFIGURE_HTML, DOWN_HTML, LEGEND_WIDTH, DISPLAY_PADDING, SETTINGS_WIDTH, UP_HTML, MIN_WINDOW_TIME, CLOSE_HTML, DISPLAY_BUTTONS_WIDTH, DEFAULT_PAGE_SIZE } from "./Viewport/Constants"
import { clip_interval } from "./Utils/Convenience"
import { IconButtonStyle } from "./Viewport/Constants"
import { Page, Pages, Region } from "./Variables/Page"
import { updateTimelineTooltipPosition } from "./Viewport/Components/TimelineTooltip"

export const useLinkDisplays = CreateVizState('linkDisplays', true)

export function throttleDebounce(func, timeout = 300) {
    let timer
    let throttled
    return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => { func.apply(this, args); }, timeout)
        if (throttled) return
        throttled = true
        func.apply(this, args)
        setTimeout(() => { throttled = false }, timeout)
    }
}

/**
 * @name Display
 * @class
 * @category Visualization
 * 
 * @description
 * Data container for grouped collections of graphs. Each display is bound to a div classed 'display'
 * 
 * Each display has its own viewing window, which is determined by Display.start_time and Display.end_time.
 * 
 * 
 */

export class Display {
    constructor(parent_display = null, displayJson = {}) {
        this.id = displayJson?.key
        this.name = `Display ${this.id}`
        this.dispatch = d3.dispatch('settimes', 'configEEG')

        this.dispatch.on('settimes', this.onSetTimes)
        this.dispatch.on('settimes.linkdisplays', this.linkDisplays)
        this.dispatch.on('settimes.updatepages', this.updatePages)
        this.dispatch.on('settimes.updateLegend', () => this.graphs.updateLegend())


        this.dispatch.on('configEEG', this.onConfigEEG)


        this.observers = []

        this.DISPLAY = displayJson

        this.playSpeed = displayJson['playSpeed']

        /**
         * The div bound to the display
         * @type {import("d3").Selection}
         */
        this.div = null

        /**
         * The total width of the display
         * @type {Pixels}
         */
        this.outer_width = null
        /**
         * The width of the display's content.
         * @type {Pixels}
         */
        this.inner_width = null

        /** 
         * The start time of the display's viewing window
         * @type {Timestamp}
         */
        this.start_time = null
        /** 
         * The end time of the display's viewing window 
         * @type {Timestamp}
         */
        this.end_time = null

        /**
         * The lower bound on the display's start time
         * @type {Timestamp}
         */
        this.min_start_time = parent_display !== null ? parent_display.start_time : VisualizationManager.file_start
        /**
         * The upper bound on the display's end time
         * @type {Timestamp}
         */
        this.max_end_time = parent_display !== null ? parent_display.end_time : VisualizationManager.file_end


        /**
         * @description Maps the current viewing window from absolute time to display pixels. (A->R)
         * @type {import("d3").ScaleTime}
         */
        this.windowScale = d3.scaleTime()

        /**
         * Maps the current viewing window from absolute time to display pixels. (A->R)
         * @type {import("d3").ScaleTime}
         */
        this.windowScaleRelative = d3.scaleTime()
        /**
         * Maps absolute time to timeline pixels. (A->T)
         * @type {import("d3").ScaleTime}
         */
        this.timelineScale = d3.scaleTime()
        /**
         * Maps relative time to timeline pixels. (R->T)
         * @type {import("d3").ScaleTime}
         */
        this.timelineScaleRelative = d3.scaleTime()

        /**
         * @type {Graphs}
         */
        this.graphs = null

        this.playSpeed = 1
        this.playing = false

        this.pages = []
        this._pages = new Pages()
        this.notLoadedRegions = []


        this.parentDisplay = null
        this.childDisplays = []
        this.pageLoadQueue = [] // list of pages

        this.color = displayJson.color

        this.hidden = false
        this.noData = false

        if (displayJson["window_size"]) {
            const label = displayJson["window_size"]
            const validPreset = WINDOW_TIME_PRESETS.find(preset => preset.label.toLowerCase() === label.toLowerCase())
            this.initial_page_size = validPreset?.time
        }
    }

    call(type, ...args) {
        this.dispatch.call(type, this, ...args)
    }
    on(...args) {
        this.dispatch.on(...args)
    }

    generatePages(start, end, window_time, total_width, modalities) {
        return Page.generatePages(start, end, window_time, total_width, modalities)
    }

    // When we want to load a display from JSON, these are the things we have to do
    // to get all of the data to display properly along with the legend.
    // This is a result of updates not propogating by d3 and instead having to call these things manually.
    fullConfigLoad() {
        this.graphs.update()
        this.resetPages()
        this.loadNeighborhood()
        this.graphs.updateLegend()
        this.graphs.render()
    }

    resetPages() {
        const pages = this.generatePages(this.min_start_time, this.max_end_time, this.window_time, this.inner_width, this.modalities, this)
        this.pages = pages
        this.page_time = this.window_time
        this.page_width = this.inner_width

        this.graphs.graphs.forEach(graph => {
            let isEmpty
            if (Array.isArray(graph.modalities)) {
                isEmpty = graph.modalities.every(modality => {
                    return !VisualizationManager.patientModalities.includes(modality)    
                })
            } else {
                isEmpty = graph.modalities === ''|| graph.modalities === undefined
            }
            
            if (isEmpty) {
                graph.noData = true;
            }
        });

        Region.updateRegions(this)
        updatePages(this)
    }

    saveToDatum(key) {
        return function (d, i, nodes) {
            d[key] = d3.select(this)
        }
    }

    //TODO: replace with d3 scales
    pageAt(t) {
        return this.pages[Math.max(Math.floor((t - this.min_start_time) / this.page_time, 0))]
    }

    get currentPageIndex() {
        return Math.max(Math.floor((this.start_time - this.min_start_time) / (this.page_time)), 0)
    }

    get currentPage() {
        return this.pages[this.currentPageIndex]
    }

    get nextPage() {
        return this.pages[this.currentPageIndex + 1]
    }

    get nextNextPage() {
        return this.pages[this.currentPageIndex + 2]
    }

    get window_page_ratio() {
        return this.window_time / this.page_time
    }

    isTimeOutsideNeighborhood(time) {

    }

    pageLoadOrder = Array(VisualizationManager.npages_cached + 1).fill(0).map((index, i) => Math.floor((i * (-1) ** i) / 2));
    get neighborhood() {
        const indices = this.pageLoadOrder.map(index => index + this.currentPageIndex)
        return indices.map(index => this.pages[index]).filter(page => page !== undefined)
    }

    popPageLoadQueue() {
        if (this.pageLoadQueue.length > 0) {
            const page = this.pageLoadQueue.shift()

            page.load().then(loadedPage => {
                this.graphs.updateLegend()
                this.graphs.render()
                updatePage(this, loadedPage)
                this.popPageLoadQueue()
            }).catch(err => console.error(err))
        }
    }

    loadNeighborhood() {
        this.pageLoadQueue = this.neighborhood.filter(page => !page.loaded)
        this.popPageLoadQueue()
    }
    unloadOutsideNeighborhood = () => this.pages.filter(page => page.loaded).forEach(page => this.neighborhood.includes(page) || page.unload() || updatePage(this, page))

    updatePageModalities() {
        this.pages.forEach(page => page.modalities = this.modalities)
        updatePages(this)
        this.loadNeighborhood()
        this.unloadOutsideNeighborhood()
    }

    // Overridden in Custom Analysis Displays because we don't have access to all the modalities
    getAvailableTraces() {
        return VisualizationManager.modalities
    }

    onResize(rootNode = null) {
        if (!rootNode) {
            rootNode = d3.select(`#display-content-d${this.id}`).node()
        }

        if (!rootNode) {
            return // Unexpected behavior. Root Node does not exist in the DOM so we can't do anything.
        }

        const previous_inner_width = this.inner_width
        this.outer_width = Math.max(1, rootNode.getBoundingClientRect().width)
        this.inner_width = Math.max(1, this.outer_width - SETTINGS_WIDTH - LEGEND_WIDTH) // Prevent divide by 0

        const deltaPixels = this.inner_width - previous_inner_width
        const deltaTime = deltaPixels * ((this.end_time - this.start_time) / previous_inner_width)
        this.end_time = Math.max(this.start_time, this.end_time + deltaTime)

        // Update the scales so that they reference the correct new width
        this.updateScales()

        // Scale the pages by the new inner width. Width MUST be an integer
        this.pages.forEach(page => {
            page.width = Math.floor(this.timelineScaleRelative(page.end_time - page.start_time))
        })
        updatePages(this)

        // Re-render the graphs and set the appropriate viewbox and canvas width
        this.graphs.update()

        const pageSizes = VisualizationManager.pageSize
        pageSizes[this.id] = this.end_time - this.start_time
        VisualizationManager.setPageSize(structuredClone(pageSizes))

        this.call("settimes", new DisplayTimesEvent(this.start_time, this.end_time))

        clearTimeout(this.expensiveResizeOperations);
        this.expensiveResizeOperations = setTimeout(() => {
            // Only perform these actions when the resize has "finished". i.e. after x milliseconds.
            this.resetPages() // we have to reset the pages because loading is based on page width.
            this.loadNeighborhood()
            this.unloadOutsideNeighborhood()
        }, 100)
    }

    setUpResizeListener(rootNode) {
        d3.select(window).on(`resize.display.${this.id}`, () => this.onResize(rootNode))
    }

    teardown() {
        this.playing = false
        d3.select(window).on(`resize.display.${this.id}`, null)
        this.pageLoadQueue = []
    }

    init(node) {
        this.setUpResizeListener(node)
        this.outer_width = node.getBoundingClientRect().width
        this.inner_width = this.outer_width - SETTINGS_WIDTH - LEGEND_WIDTH

        this.start_time = this.min_start_time
        this.end_time = Math.min(this.max_end_time, this.min_start_time + (this.initial_page_size ?? DEFAULT_PAGE_SIZE))

        this.resetPages()
        this.updateScales()
    }

    get window_timeline_width() {
        return this.timelineScaleRelative(this.window_time)
    }

    get window_time() {
        return this.end_time - this.start_time
    }

    set window_time(t) {
        if (t < MIN_WINDOW_TIME) {
            console.error(`[⚠️] Attempted to set Display ${this.id}'s window_time to ${t / 1000}s, below MIN_WINDOW_TIME=${MIN_WINDOW_TIME / 1000} s.`)
        } else {
            this.end_time = this.start_time + t
        }
    }

    // the modalities that are currently active on the display.
    get modalities() {
        return this.graphs.modalities
    }

    onSetTimes(e) {
        const [clipped_start, clipped_end] = clip_interval(e.start_time, e.end_time, this.min_start_time, this.max_end_time)

        const new_window_time = clipped_end - clipped_start

        if (new_window_time < MIN_WINDOW_TIME) return

        this.start_time = clipped_start
        this.end_time = clipped_end
        this.updateScales()
        updateTimelineTooltipPosition(this, this.timelineScale((this.start_time + this.end_time) / 2))
    }

    onConfigEEG(what, value) {
        this.graphs.graphs.forEach(graph => {
            var eegMontageName = graph.eegConfig.montageName
            var LFF = graph.eegConfig.LFF
            var HFF = graph.eegConfig.HFF
            var notch = graph.eegConfig.notch
            var sensitivity = graph.eegConfig.sensitivity
            // var speed = graph.display.playSpeed
            var speed = this.playSpeed
            var color = graph.eegConfig.color
            if (what === 'speed')
                this.playSpeed = value
            else if (graph.type === 'EEG') {
                switch (what) {
                    case 'montage': eegMontageName = value; break;
                    case 'LFF': LFF = value; break;
                    case 'HFF': HFF = value; break;
                    case 'notch': notch = value; break;
                    case 'sensitivity': sensitivity = parseFloat(value); break;
                    case 'speed': speed = value; break;
                    case 'color': color = value; break;
                    default:
                }
                this.graphs.configEEG(graph, eegMontageName, LFF, HFF, notch, sensitivity, speed, color)
            }

        });
    }


    linkDisplays(e) {
        if (VisualizationManager.linkDisplays && e.source !== 'display.linkdisplays') {
            VisualizationManager.displays.filter(display => display !== this).forEach(display => {
                let new_start_time = Math.min(e.start_time, display.max_end_time - display.window_time)
                if (VisualizationManager.heldKeys.includes('shift'))
                    new_start_time = Math.min(Math.max(e.end_time, display.end_time) - display.window_time, e.start_time)
                new_start_time = Math.min(Math.max(display.min_start_time, new_start_time), display.max_end_time - display.window_time)
                display.call('settimes', new DisplayTimesEvent(new_start_time, new_start_time + display.window_time, 'display.linkdisplays'))
            })
        }
    }

    setDenseTableIsCollapsed() {
        // Hide the data until the resize is finished.
        // Prevents visual clutter.
        d3.select(`#graphs-data-d${this.id}`).attr("opacity", 0)

        this.getDenseTableNode().style.width = "fit-content" // Be only as big as necessary.

        // Wait for the d3 update to finish before updating the display
        setTimeout(() => {
            this.onResize()
            d3.select(`#graphs-data-d${this.id}`)
                .attr("opacity", 1)
        }, 0)
    }

    renderDenseTable() {
        if (this.modalities.length === 0 || this.graphs.graphs.some(graph => graph.type === "EEG")) {
            return
        }
        return
    }

    updateScales(e) {
        const STROKE_WIDTH = 1
        const OUTPUT_SCALE = [STROKE_WIDTH, this.inner_width - STROKE_WIDTH]

        this.windowScale
            .domain([this.start_time, this.end_time]) // Input Scale
            .range(OUTPUT_SCALE)

        this.windowScaleRelative
            .domain([0, this.window_time])
            .range(OUTPUT_SCALE)

        this.timelineScale
            .domain([this.min_start_time, this.max_end_time])
            .range(OUTPUT_SCALE)

        this.timelineScaleRelative
            .domain([0, this.max_end_time - this.min_start_time])
            .range(OUTPUT_SCALE)
    }

    updatePages() {
        this.loadUnloadPages()
    }

    loadUnloadPages = throttleDebounce(() => {
        this.loadNeighborhood()
        this.unloadOutsideNeighborhood()
    }, 500)


    // Accessing DOM nodes programatically so we don't mess up the names...
    getDenseTableNode() {
        return d3.select(`#dense-table-d${this.id}`).node()
    }

    getOverlayNode() {
        return d3.select(`#display-overlay-d${this.id}`).node()
    }

    getContentNode() {
        return d3.select(`#display-content-d${this.id}`).node()
    }

    getBarNode() {
        return d3.select(`#display-bar-d${this.id}`).node()
    }

    /**
     * @function
     * @param {import("d3").Selection} enter 
     */
    static enter(enter) {
        const divs = enter.append('div')
            .attr('class', 'display')
            .attr('style', display_div_style)

        const overlay = divs.append('div')
            .attr("id", display => `display-overlay-d${display.id}`)
            .attr("style", display_overlay_style)

        const content = divs.append('div')
            .attr("id", display => `display-content-d${display.id}`)
            .attr('class', 'display-content')
            .attr('style', display_content_style)

        const denseTable = divs.append("div")
            .attr("id", display => `dense-table-d${display.id}`)
            .attr("class", "denseTable")
            .style("display", "flex") // Display next to the display content
            .style("width", "fit-content")
            .style("height", "auto") // Full Height

        divs.each(this.saveToDatum('div'))
        content.each(this.saveToDatum('content'))

        // Create d3 components
        content.call(createDisplayContent)

        // Create React components
        const displays = enter.data()
        displays.forEach(display => display.renderDenseTable())
    }

    static update(update) { } // This doesn't get called :/

    static exit(exit) {
        exit.remove()
        exit.each(display => display.teardown())
    }

}

const display_div_style = `
    position: relative;
    display: flex;
    padding: ${DISPLAY_PADDING}px;
    width: 100%;
    height: auto;
    border-radius: 5px;
    background: #FFFFFF;
`

const display_overlay_style = `
    position: absolute;
    inset: 0;
    z-index: 1;
    pointer-events: none
`

const display_content_style = `
    flex: 0 1 100%;
    height: auto;
    min-width: 0px;
    display: flex;
    flex-direction: column;
`

const display_bar_style = `
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 ${DISPLAY_BUTTONS_WIDTH}px;
    height: auto;
    border: 1px solid #CCCCCC;
    border-radius: 5px;
`

export const useDisplays = CreateVizState('displays', [])
export const useLastHoveredDisplay = CreateVizState('lastHoveredDisplay', undefined)

VizEffect(displays => {
    d3.select('#Visualization')
        .selectAll('div.display')
        .data(displays, display => display.id)
        .join(Display.enter, Display.update, Display.exit)

    // This is the active display that is used for hotkeys
    const newActiveDisplay = displays.length > 0 ? displays[0] : null
    VisualizationManager.setLastHoveredDisplay(newActiveDisplay)
}, 'displays')


function createDisplayContent(selection) {
    selection.each(function (display, i, nodes) {
        display.init(nodes[i])
    })

    selection.call(createXAxis)
    selection.call(createTimeline)
}

function createDisplayBar(bar) {
    bar
        .append('button')
        .attr('style', IconButtonStyle)
        .html(UP_HTML)
        .on('click', (e, display) => {
            const displays = VisualizationManager.displays
            const indx = displays.indexOf(display)
            if (indx <= 0) return

            const next_displays = [...displays]
            next_displays[indx - 1] = display
            next_displays[indx] = displays[indx - 1]

            VisualizationManager.setDisplays(next_displays)
        })

    bar
        .append('button')
        .attr('style', IconButtonStyle)
        .style('padding', 'auto 0 0 0')
        .html(CONFIGURE_HTML)
        .on('click', (e, display) => {
        })
    bar
        .append('button')
        .attr('style', IconButtonStyle)
        .style('padding', 'auto 0 0 0')
        .html(CLOSE_HTML)
        .on('click', (e, display) => {
            VisualizationManager.setDisplays(prev => prev.filter(d => d !== display))
        })

    bar
        .append('button')
        .attr('style', IconButtonStyle)
        .html(DOWN_HTML)
        .on('click', (e, display) => {
            const displays = VisualizationManager.displays
            const indx = displays.indexOf(display)
            if (indx >= displays.length - 1) return

            const next_displays = [...displays]
            next_displays[indx + 1] = display
            next_displays[indx] = displays[indx + 1]

            VisualizationManager.setDisplays(next_displays)
        })

}

export class DisplayEvent {
    constructor(type, source) {
        Object.defineProperties(this, {
            type: {
                value: type,
                writable: false,
                configurable: false,
                enumerable: true
            },
            source: {
                value: source,
                writable: false,
                configurable: false,
                enumerable: true
            }
        })
    }
}


export class DisplayTimesEvent extends DisplayEvent {
    constructor(start_time, end_time, source, type) {
        super('settime', source)

        if (start_time instanceof Date) {
            start_time = start_time.getTime()
        }

        if (end_time instanceof Date) {
            end_time = end_time.getTime()
        }

        switch (type) {
            case 'set':
                break
            case 'move':

                break
            case 'max':
                break
            default:
        }

        Object.defineProperties(this, {
            start_time: {
                value: start_time,
                writable: false,
                configurable: false,
                enumerable: true
            },
            end_time: {
                value: end_time,
                writable: false,
                configurable: false,
                enumerable: true
            }
        })
    }
}





